import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { Rive } from '@rive-app/canvas';
import { QuizOption } from '../../data/quiz';

@Component({
  selector: 'app-quiz-list-option',
  templateUrl: './quiz-list-option.component.html',
  styleUrl: './quiz-list-option.component.scss'
})
export class QuizListOptionComponent implements AfterViewInit, OnDestroy {
  @Input() option: QuizOption;

  @ViewChild('animation') riveAnimation: ElementRef;

  private riveInstance: Rive | undefined;

  ngAfterViewInit(): void {
    if (this.option.rive) {
      const canvas = this.riveAnimation.nativeElement;

      this.riveInstance = new Rive({
        src: `assets/rive/${this.option.rive.fileName}.riv`,
        canvas,
        autoplay: true,
        animations: this.option.rive.animations,
        onLoad: () => this.riveInstance?.resizeToCanvas(),
      });
    }
  }

  ngOnDestroy(): void {
    this.riveInstance?.stop();
    this.riveInstance?.cleanup();
  }
}
