import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var fbq: (action: string, eventName: string, eventData?: any, options?: object) => void;

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor() {
    //initting pixel here instead of index.html
    fbq('init', environment.onboardingMetaPixelId);
  }

  track(event: 'first_open' | 'subscribe' | 'paywall_opened' | 'checkout_opened' | 'signed_in', email?: string | null): void {
    const eventID = `${event}_${Date.now()}`; //this is used to dedublicate events sent from server & browser

    switch (event) {
      case 'first_open':
        fbq('track', 'Lead', null, { eventID });
        break;
      case 'subscribe':
        fbq('track', 'Purchase', null, { eventID, em: email });
        break;
      case 'paywall_opened':
        fbq('track', 'ViewContent', null, { eventID });
        break;
      case 'checkout_opened':
        fbq('track', 'InitiateCheckout', null, { eventID });
        break;
      case 'signed_in':
        fbq('track', 'CompleteRegistration', null, { eventID });
        break;
      default:
        break;
    }
  }
}
