import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '../guards/auth.guard';
import { AndroidComponent } from './components/android/android.component';
import { EmailComponent } from './components/email/email.component';
import { GoalInputComponent } from './components/goal-input/goal-input.component';
import { NameComponent } from './components/name/name.component';
import { PaywallComponent } from './components/paywall/paywall.component';
import { PreparationComponent } from './components/preparation/preparation.component';
import { QuizStepComponent } from './components/quiz-step/quiz-step.component';
import { SignInWithEmailComponent } from './components/sign-in-with-email/sign-in-with-email.component';
import { StartComponent } from './components/start/start.component';
import { WaitingListComponent } from './components/waiting-list/waiting-list.component';
import { OnboardingRootComponent } from './onboarding-root.component';
import { PaymentResultComponent } from '../components/payment-result/payment-result.component';

const routes: Routes = [
  {
    path: '',
    component: OnboardingRootComponent,
    children: [
      { 
        path: '', 
        pathMatch: 'full',
        redirectTo: 'intro', 
        //canActivate: [onboardingStartGuard],
        //component: StartComponent,
      },
      { 
        path: 'quiz', 
        redirectTo: 'quiz/0', 
        pathMatch: 'full' 
      },
      {
        path: 'quiz/:index',
        component: QuizStepComponent,
      },
      {
        path: 'intro',
        component: StartComponent,
      },
      {
        path: 'goal',
        component: GoalInputComponent,
      },
      {
        path: 'android',
        component: AndroidComponent,
      },
      {
        path: 'waiting-list',
        component: WaitingListComponent,
      },
      {
        path: 'checkout',
        canActivate: [authGuard],
        component: PaywallComponent,
      },
      {
        path: 'payment-result',
        component: PaymentResultComponent,
      }, 
      {
        path: 'login',
        component: EmailComponent,
      }, 
      {
        path: 'ready',
        component: PreparationComponent,
      }, 
      {
        path: 'email-sign-in',
        component: SignInWithEmailComponent,
      }, 
      {
        path: 'name',
        component: NameComponent,
      }
    ],
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ]
})
export class OnboardingRoutingModule { }
