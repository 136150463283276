<div class="p-30 d-flex flex-column align-items-center">
    <canvas 
        *ngIf="rive"
        #animation
        class="w-100 animation"
        width="640"
        [height]="480 / rive.ratio"
    ></canvas>
    <h4 class="text-center" [ngClass]="{ 'mt-20': rive }">{{title}}</h4>
    <div class="fs-16 fw-light text-center">{{message}}</div>
    <div mat-dialog-actions class="d-flex buttons justify-content-center mt-20 w-100">
        <button *ngIf="isConfirmation" mat-flat-button mat-dialog-close (click)="close(false)" class="">{{cancelButtonTitle}}</button>
        <button mat-flat-button mat-dialog-close (click)="close(true)" [ngClass]="{ 'w-100': !isConfirmation }" class="tagus">{{okButtonTitle}}</button>
    </div>
</div>
