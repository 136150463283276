<div class="row email-container align-items-center flex-column pb-20">
    <div class="col-12 mt-20 top-section d-flex align-items-center flex-column">
        <div class="text-center w-100">
            <h3 [innerHTML]="title"></h3>
            <div class="subtitle mt-20">{{ subtitle }}</div>
        </div>

        <div class="tagus-form-group mt-30 w-100">
            <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
            <mat-form-field appearance="fill">
                <i class="ri-mail-line"></i>
                <mat-label>Enter email address</mat-label>
                <input #emailInput matInput autocomplete="email" placeholder="harry@hogwarts.com" type="email" [formControl]="email" (keyup.enter)="enterPressed()" required>
                <mat-error *ngIf="email.invalid">Email address is invalid</mat-error> 
            </mat-form-field>
        </div>

        <button mat-flat-button class="tagus mt-20" [disabled]="email.invalid" (click)="continueButtonClicked()">Continue</button>
    </div>
</div>
