import * as amplitude from '@amplitude/analytics-browser';
import { Injectable } from '@angular/core';
import { FirebaseOptions, initializeApp } from '@firebase/app';
import { Auth, AuthProvider, browserLocalPersistence, createUserWithEmailAndPassword, FacebookAuthProvider, User as FirebaseUser, getAuth, GoogleAuthProvider, OAuthProvider, signInWithCustomToken, signInWithEmailAndPassword, signInWithPopup, signOut } from '@firebase/auth';
import { BehaviorSubject, filter, from, map, mergeMap, Observable, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventsService } from './events.service';

export type AuthProviderType = 'facebook' | 'google' | 'apple';

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyD5ClyeP0iWEm_IPanMVULE6YFkjOYehek",
  authDomain: "app.getpeech.com",
};

//TODO: move all the analytics calls from here

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private auth: Auth;

  private userSubject = new BehaviorSubject<FirebaseUser | null>(null);
  private tokenSubject = new BehaviorSubject<string | null>(null);
  private authInitializedSubject = new BehaviorSubject<boolean>(false);

  authInitialized$ = this.authInitializedSubject.asObservable();
  user$: Observable<FirebaseUser | null> = this.userSubject.asObservable();
  token$: Observable<string | null> = this.tokenSubject.asObservable();

  constructor(private pixel: EventsService) {
    const app = initializeApp(firebaseConfig);

    this.auth = getAuth(app);
    this.auth.useDeviceLanguage();
    this.auth.setPersistence(browserLocalPersistence);

    this.auth.onAuthStateChanged(user => {
      this.userSubject.next(user);
      this.authInitializedSubject.next(true);

      if (user) {
        user.getIdToken().then(token => this.tokenSubject.next(token));
      } else {
        this.tokenSubject.next(null);
      }
    });
  }

  login(type: AuthProviderType) {
    const provider = this.getProvider(type);
  
    return from(signInWithPopup(this.auth, provider))
      .pipe(
        map(result => result.user),
        tap(user => {
          this.userSubject.next(user);
          user.getIdToken().then(token => this.tokenSubject.next(token));
        }),
        //map(user => !!user),
    );
  }

  loginWithToken(token: string) {
    return from(signInWithCustomToken(this.auth, token)).pipe(
      map(result => result.user),
      tap(user => {
        if (user) {
          amplitude.track('web_login', { provider: 'email' });
        }
      }),
    );
  }

  signUpWithEmail(email: string) {
    const defaultPassword = this.generateDefaultPassword();
    const adjustedEmail = environment.production ? email : email.replace('@', '+staging@');

    return from(createUserWithEmailAndPassword(this.auth, adjustedEmail, defaultPassword)).pipe(
      map(result => result.user),
      tap(user => {
        // user.accessToken
        if (user) {
          this.pixel.track('signed_in');

          amplitude.track({
            event_type: 'web_login',
            event_properties: {
              provider: 'email',
            },
            user_properties: {
              web_email: email,
            }
          });
          // amplitude.track('web_login', { email });
        }
      }),
    );
  }
  
  logout() {
    return from(signOut(this.auth)).pipe(
      tap(() => {
        this.userSubject.next(null);
        this.tokenSubject.next(null);
      })
    );
  }

  //not sure we should do it on our own
  updateToken() {
    this.user$.pipe(
      take(1),
      filter(user => !!user),
      mergeMap(user => from(user!.getIdToken())),
      tap(token => this.tokenSubject.next(token)),
    )
  }
  
  private getProvider(type: AuthProviderType): AuthProvider {
    let provider;

    switch (type) {
      case 'apple':
        provider = new OAuthProvider('apple.com');
        provider.addScope('name');
        provider.addScope('email');
        break;
      case 'facebook':
        provider = new FacebookAuthProvider();
        provider.addScope('email');
        provider.addScope('public_profile');
        break;
      case 'google':
        provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/user.emails.read'); //https://developers.google.com/identity/protocols/oauth2/scopes#people
        break;
    }

    return provider;
  }

  private generateDefaultPassword(): string {
    // example: 69d3b5abc
    return crypto.randomUUID().toString().substring(0, 10).replace('-', '');
  }
}
