<div
    class="pt-5 pb-50"
    [class.component-dark-theme]="themeService.isDark()"
>
    <div class="row align-items-center">
        <div class="col-lg-5 d-flex justify-content-center">
            <img class="top-image" src="assets/img/top2.png">
        </div>
        <div class="col-lg-7 mt-20 mb-20">
            <h1 class="display-3">Convert anything into beautiful audio</h1>
            <div class="lead">Welcome to Peech! Reading can be tough and time-consuming, but listening is effortless. <br>Peech turns any text file, pdf, real book, or web article into audio. <br>Save hours, enhance your productivity, retain more of what you learn, and give your eyes some rest.</div>
        </div>
    </div>

    <mat-divider class="mt-25 mb-25"></mat-divider>
    <div class="row">
        <h1 class="display-6">What people think <span class="main-color">about us</span></h1>
    </div>
    <div class="row reviews align-items-stretch justify-content-center mt-20">
        <app-user-review
            *ngFor="let review of reviews"
            [data]="review">
        </app-user-review>
    </div>
</div>