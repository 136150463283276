import { UserReview } from "src/app/shared/user-review/user-review.component";
import { AudioTrack } from "../../shared/audio-track/audio-track.component";
import { audioTracks } from "./sounds";
import { challengesBookMap, goalsBooksMap } from "./books";

export interface RiveAnimationConfig {
  fileName: string;
  animations: string[];
  ratio: number;
  backgroundImagePath?: string;
  marginTop?: string; //TODO: this is workaround because some animations have redundant space on top
}

export interface QuizOption {
    title?: string;
    emoji?: string;
    imageFilePath?: string;
    imageMode?: 'cover' | 'center';
    rive?: RiveAnimationConfig;
}

export interface Quiz {
    title: string;
    subTitle?: string;
    options?: QuizOption[];
    isTilesDisplay?: boolean; //in case we want to show options as tiles
    isChips?: boolean;
    imageFilePath?: string;
    rive?: RiveAnimationConfig;
    videoFilePath?: string;
    mediaCite?: boolean;
    headline?: string;
    isNegative?: boolean; //this is just for headline as of now
    message?: string; //this is for chat like message
    topIcon?: string;
    audioTracks?: AudioTrack[];
    isVoting?: boolean;
    hasSocialProof?: boolean; //it should be either social proof or review not both
    userReview?: UserReview;
    isMultiple: boolean;
    isRequired: boolean;
}

export const highlightText = (text: string, isRed: boolean = false) => {
  const className = isRed ? 'red-color' : 'main-color';
  return `<span class="${className}">${text}</span>`;
}

export const interests = [
  '🧠 Personal Growth',
  '💼 Business',  
  '💻 Tech & IT',
  '📊 Finance & Investing',
  '📣 Marketing',
  '🏥 Health & Medicine',
  '🌍 Environment',
  '📖 Books',
  '🎥 Movies',
  '🛡 Fantasy',
  '🔍 Thriller & Mystery',
  '❤️ Romance',
  '👪 Family',
  '🚀 Motivation',
  '📜 Religion & Spirituality',
  '⚖️ Law',
  '🧮 Economics',
  '✈️ Travel',
  '🎨 Arts & Design',
  '🏋️ Fitness',
  '💆 Mental Wellness',
  '🍳 Food & Cooking',
  '📜 History',
  '🎙️ Content Creation',
  '🏡 Real Estate',
  '📰 News',
] as const;

export const goals = [
  '🏠 Buy a house',
  '👩‍💼 Getting a promotion',
  '💸 Pay the Debt',
  '📊 Starting my own business',
  '👩‍❤️‍👩 Relationship commitment',
  '👨‍👩‍👧 Parenthood',
  '🌍 Travel the World',
  '🏖 Retirement planning',
  '🤯 Mental and emotional well-being',
  '💪 Improve health and fitness',
  '🎓 Get the best education',
  '🤔 Other',
] as const;

export const jobChallenges = [
  '💸 Low income',
  '🪙 Financial dependence',
  '⏳ No free time',
  '🔄 Routine',
  '😰 Constant stress',
  '🤢 Toxic environment',
  '🌱 No growth',
  '🧍 Feeling undervalued',
  '😎 Everything is perfect',
  '❌ I\'m not currently working',
  '✏️ Other',
] as const;

export type Interest = typeof interests[number];
export type Goal = typeof goals[number];
export type JobChallenge = typeof jobChallenges[number];

const defaultBook2 = goalsBooksMap['💸 Pay the Debt'][0];
const defaultBook3 = goalsBooksMap['🤔 Other'][0];
const defaultBook1 = challengesBookMap['✏️ Other'];

//not used rives: confident, rocket
export const quizSteps = {
  goal: {
    title: 'What is your current main focus in life?',
    //subTitle: `We’ll help you make it happen, step by step`, //to save some vertical space
    //isTilesDisplay: true,
    options: [
      ...goals,
    ].map(title => ({ title })),
    isMultiple: false,
    isRequired: true,
  } as Quiz,

  goalInfo: {
    title: `You’ve just taken the first big step!`,
    subTitle: `Peech has helped over 4 million people continue learning and growing every day. Let us do the same for you.`,
    rive: {
      // fileName: 'rocket',
      // animations: ['char', 'rocket'],
      fileName: 'student_group',
      animations: [],
      ratio: 1024 / 833,
      marginTop: '-20%',
    },
    isMultiple: false,
    isRequired: false,
  } as Quiz,

    gender: {
      title: `Select your ${highlightText('gender')}`,
      subTitle: 'Let us personalize your experience',
      options: [
        {
          title: '👱‍♂️ Male',
          rive: {
            fileName: 'simple_boy',
            animations: ['Timeline 1'],
            ratio: 1.49 / 1,
          },
        }, {
          title: '👩 Female',
          rive: {
            fileName: 'simple_grl',
            animations: ['Timeline 1'],
            ratio: 1.49 / 1,
          },
        }, {
          title: '🧓 Other'
        },
      ],
      isTilesDisplay: true,
      isMultiple: false,
      isRequired: true,
    } as Quiz,

    age: {
      title: 'What is your age?',
      // options: [
      //   {
      //     title: '< 21',
      //     rive: {
      //       fileName: 'age_01',
      //       animations: ['Character_Loop'],
      //       ratio: 1.49 / 1,
      //     },
      //   }, {
      //     title: '21-34',
      //     rive: {
      //       fileName: 'age_02',
      //       animations: ['Character_Loop'],
      //       ratio: 1.49 / 1,
      //     },
      //   }, {
      //     title: '35-44',
      //     rive: {
      //       fileName: 'age_03',
      //       animations: ['Character_Loop'],
      //       ratio: 1.49 / 1,
      //     },
      //   }, {
      //     title: '45+',
      //     rive: {
      //       fileName: 'age_04',
      //       animations: ['Character_Loop'],
      //       ratio: 1.49 / 1,
      //     },
      //   },
      // ] as QuizOption[],
      options: [
        '🐣 < 21',
        '🚀 21-34',
        '💼 35-44',
        '🏆 45-54',
        '🌴 55-64',
        '👵 65+',
      ].map(title => ({ title })),
      //isTilesDisplay: true,
      isMultiple: false,
      isRequired: true,
    },

    device: {
      title: 'Which type of device do you use?',
      isTilesDisplay: true,
      options: [
        {
          title: 'iOS',
          imageFilePath: 'assets/apple_black.png',
        },
        {
          title: 'Android',
          imageFilePath: 'assets/android.png',
        },
        {
          title: 'Both'
        },
      ],
      isMultiple: false,
      isRequired: true,
    } as Quiz,

    jobChallenges: {
      title: `What are the ${highlightText('challenges', true)} with your current job?`,
      subTitle: 'Select all that apply:',
      options: [
        ...jobChallenges,
      ].map(title => ({ title })),
      isMultiple: true,
      isRequired: true,
    } as Quiz,

    amountInfo: {
      title: 'Join Over 1 Million Professionals Your Age Worldwide',
      subTitle: 'Discover how Peech boosts your productivity and helps you succeed',
      mediaCite: true,
      // rive: {
      //   fileName: 'map',
      //   animations: ['appear', 'both'], //["idle","female","both","male","appear"]
      //   ratio: 1.75 / 1,
      //   backgroundImagePath: '../../../../assets/onboarding/map.png',
      // },
      //hasSocialProof: true,
      isRequired: false,
      isMultiple: false,
    },

    level: {
      title: 'What best describes your current professional status?',
      subTitle: 'It helps us tailor your experience',
      options: [
        '👔 Employed Full-Time',
        '💼 Self-Employed/Entrepreneur',
        '👨‍💻 Freelancer/Consultant',
        '🏠 Homemaker',
        '🎓 Continuing Education',
        '👴 Retired',
        '🔎 Seeking New Opportunities',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },


    lackOfKnowledge: {
      title: `Do you ever feel like you’re missing the right information to reach your goals?`,
      options: [
        '😩 Always',
        '😓 Frequently',
        '😐 Occasionally',
        '🙂 Rarely',
        '😎 Never',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    
    lackOfKnowledgeInfo: {
      title: highlightText("You’re not alone!"),
      subTitle:
        `Many people struggle to reach their goals due to a lack of time or information. We’re here to help`,
      rive: {
        fileName: 'ambivert',
        animations: ['char1', 'char2', 'blink'],
        ratio: 1.75 / 1,
      },
      isMultiple: false,
      isRequired: false,
    },

    balance: {
      title: 'Is it hard for you to balance life, family, and personal growth?',
      //subTitle: '',
      options: [
        '⚖️ Yes, it’s a constant struggle',
        '🏃 Yes, quite often',
        '🤷 Sometimes',
        '😊 Not really',
        '💪 No, I manage well',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },

    distraction: {
      title: `How easily do you ${highlightText('get distracted', true)} when trying to read or study?`,
      //subTitle: '',
      options: [
        '📱 Extremely easily',
        '💻 Very easily',
        '😅 Somewhat easily',
        '🧐 Rarely',
        '🙅 Never',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },

    retention: {
      title: `Do you ever feel like you spend time reading but ${highlightText('don’t remember', true)} much?`,
      options: [
        '🤔 Yes, all the time',
        '😕 Yes, often',
        '😐 Sometimes',
        '😊 Rarely',
        '🤓 Never',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },

    retentionInfo: {
      title: highlightText('Did You Know?'),
      subTitle: 'Combining reading and listening boosts retention by up to 70%. That’s why so many people love Peech—it turns reading into engaging audio for better results.',
      rive: {
        fileName: 'boy_with_book_outside',
        animations: ['Timeline 1'],
        ratio: 1.1 / 1,
        marginTop: '-10%',
      },
      isMultiple: false,
      isRequired: false,
    },

    anxiety: {
      title: `Do you ${highlightText('feel stressed or anxious', true)} about handling responsibilities and personal goals?`,
      options: [
        '😰 Yes, it’s significant',
        '😟 Yes, to some extent',
        '😬 Occasionally',
        '🙂 Rarely',
        '😌 Never',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },

    procrastination: {
      title: 'Do you ever procrastinate on tasks that could help you grow, like reading or learning something new?',
      options: [
        '🐌 Always',
        '🐢 Frequently',
        '🐇 Sometimes',
        '🦅 Rarely',
        '🚀 Never',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },

    interests: {
      title: 'What fields below are most interesting to you?',
      options: [
        ...interests,
        '🤔 Others',
      ].map(title => ({ title })),
      isChips: true,
      isMultiple: true,
      isRequired: true,
    },

    //TODO: add audio to listen to after this step

    // interestAreasInfo: {
    //   title: `You're on the right track!`,
    //   subTitle: `Whether you’re deepening your expertise or exploring new interests, Peech is here to support you every step of the way. Experience how your reading material can come alive with our AI:`,
    //   rive: {
    //     fileName: 'impressed',
    //     animations: ['char', 'skirt', 'birds', 'wings'],
    //     ratio: 1.75 / 1,
    //   },
    //   isMultiple: false,
    //   isRequired: false,
    // },

    interestsInfo: {
      title: `You’re in Good Company! ${highlightText('Over 1.5 million individuals')} using Peech share similar interests`,
      subTitle: 'Stay tuned! Soon, you’ll discover how Peech helps you stay connected with what matters to you, both in and outside of work.',
      topIcon: '🤝',
      userReview: {
        message: 'The app is impressive. easy to listening to, no robotic voice, easy to use. productive use of my driving time. would recommend.',
        name: 'Jerin Barnes',
        avatarUrl: 'assets/img/avatars/m1.jpg',
      },
      isMultiple: false,
      isRequired: false,
    },

    technology: {
      title: `How do you feel about using technology to support your ${highlightText('learning and growth?')}`,
      options: [
        '🤩 Very excited and open',
        '😊 Open, but cautious',
        '😐 Neutral',
        '📘 Prefer traditional methods',
        '🙅 Avoid using technology',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    technologyInfo: {
      title: highlightText('Learning in the Modern World'),
      subTitle: 'Transform your learning with AI! Peech instantly turns reading into audio, fueling your self-growth and ambitious goals.',
      rive: {
        fileName: 'boy_with_book',
        animations: [],
        ratio: 1024 / 882,
        marginTop: '-20%',
      },
      isMultiple: false,
      isRequired: false,
    },

    audio: {
      title: `Have you tried using ${highlightText('audiobooks or podcasts')} to learn on the go?`,
      options: [
        '🎧 Yes, regularly',
        '🎙️ Yes, occasionally',
        '👂 No, but I’m interested',
        '🤔 No, not really',
        '🚫 No, and I’m not interested',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    audioInfo: {
      title: highlightText(`Audio Learning`),
      subTitle: 'Reading takes time, while listening boosts focus and retention. Successful people like Warren Buffett and Michelle Obama use audio learning daily to stay ahead.',
      rive: {
        fileName: 'relax_man',
        animations: ['Timeline 1'],
        ratio: 1024 / 937,
        marginTop: '-10%',
      },
      isMultiple: false,
      isRequired: false,
    },

    eyes: {
      title: `How often do you feel ${highlightText('eye strain or tiredness', true)} from working or using screens for too long?`,
      options: [
        '👁️ Always',
        '👀 Frequently',
        '😕 Sometimes',
        '😊 Rarely',
        '😌 Never',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    eyesBreak: {
      title: highlightText('🌿 Give Your Eyes a Break!'),
      subTitle: 'Eye strain from long study sessions or screen time is common, but there’s an easy way to relax your eyes right now. Follow the dot with your eyes to give them a quick refresh!',
      videoFilePath: 'assets/onboarding/eyes.mp4',
      isMultiple: false,
      isRequired: false,
    },

    screenTime: {
      //TODO: think - maybe just voting with picture?
      title: `How satisfied are you with your ${highlightText('current screen time')} habits?`,
      options: [
        'Very satisfied',
        'Somewhat satisfied',
        'Neutral',
        'Somewhat dissatisfied',
        'Very dissatisfied',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    screenTimeSocialProof: {
      title: `You are not alone. There are ${highlightText('more than 3M users')} in Peech who improve their digital habits every single day`,
      topIcon: '👐',
      userReview: {
        message: 'How the perfect AI reader cut my screen time by 25% in a week',
        name: 'Robert Ashton',
        avatarUrl: 'assets/img/avatars/7.png',
        logoUrl: 'assets/onboarding/ai_writer.svg'
      } as UserReview,
      isRequired: false,
      isMultiple: false,
    },

    screenTimeQuestion: {
      title: `What is your daily average Screen Time on your phone?`,
      subTitle: `This can be checked in Screen Time section of the Settings app`,
      options: [
        '1-2 hours',
        '2-3 hours',
        '3-5 hours',
        '5-8 hours',
        '8-12 hours',
        '12+ hours',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    screenTimeResult: {
      title: `The bad news is that you will spend this much time staring at your phone:`,
      topIcon: '😵‍💫',
      isNegative: true,
      headline: '100500 hours',
      isRequired: false,
      isMultiple: false,
    },
    screenTimeImprovement: {
      title: `The good news is that Peech can help you get back `,
      subTitle: `Less screen time means more focus, better health, and time for the things you love!`,
      topIcon: '🎉',
      headline: '5+ years',
      isRequired: false,
      isMultiple: false,
    },

    relaxTime: {
      title: `How often do you wish you had ${highlightText('more time')} for relaxation or hobbies?`,
      options: [
        '⏰ All the time',
        '🕑 Often',
        '⏳ Sometimes',
        '🕔 Rarely',
        '🕰️ Never',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },

    relaxTimeGoal: {
      title: 'What would you do first if you had more free time?',
      subTitle: 'Select one:',
      isTilesDisplay: true,
      options: [
        {
          title: 'Get into creative hobbies',
          imageFilePath: 'assets/onboarding/pictures/creative_hobbies.png',
        },
        {
          title: 'Spend more time with loved ones',
          imageFilePath: 'assets/onboarding/pictures/loved_ones.png',
        },
        {
          title: 'Boost personal growth',
          imageFilePath: 'assets/onboarding/pictures/personal_growth.png',
        },
        {
          title: 'Prioritize health and well-being',
          imageFilePath: 'assets/onboarding/pictures/well_being.png',
        },
      ],
      isMultiple: false,
      isRequired: true,
    } as Quiz,

    language: {
      title: `Do you speak or study any language other than English?`,
      isTilesDisplay: true,
      options: [
        { 
          title: 'Spanish',
          emoji: '🇪🇸',
        },
        { 
          title: 'French',
          emoji: '🇫🇷',
        },
        { 
          title: 'German',
          emoji: '🇩🇪',
        },
        { 
          title: 'Italian',
          emoji: '🇮🇹',
        },
        { 
          title: 'Portuguese',
          emoji: '🇵🇹',
        },
        { 
          title: 'Chinese',
          emoji: '🇨🇳',
        },
        { 
          title: 'Hindi',
          emoji: '🇮🇳',
        },
        { 
          title: 'None of those',
          emoji: '🤔',
        },
      ],
      isMultiple: false,
      isRequired: true,
    },

    languageInfo: {
      title: highlightText('Languages Open Doors'),
      subTitle: 'Peech supports any language you know or learn. Check it out:',
      rive: {
        fileName: 'people_group_one',
        animations: [],
        ratio: 1024 / 675,
      },
      isMultiple: false,
      isRequired: false,
    },

    time: {
      title: 'When do you usually read or listen to content?',
      subTitle: 'Select all that apply:',
      options: [
        {
          emoji: '☕️',
          title: 'In the morning',
        }, {
          emoji: '🏃‍♂️',
          title: 'While working out',
        }, {
          emoji: '🚗',
          title: 'On my commute',
        }, {
          emoji: '🛋',
          title: 'While taking a break',
        },
      ],
      isMultiple: true,
      isRequired: true,
    } as Quiz,

    duration: {
      title: 'How many hours per day you could potentially listen to audio content?',
      options: [
        {
          title: '⏰ Less than 1 hour',
        }, {
          title: '⏱️ 1–2 hours',
        }, {
          title: '⌛ 2–3 hours',
        }, {
          title: '🕰️ 3–4 hours',
        }, {
          title: '🕛 More than 4 hours',
        },
      ],
      isMultiple: false,
      isRequired: true,
    },
    durationInfo: {
      title: 'That\'s 4x more than most people!',
      subTitle: 'You are in top 25% among your age.',
      rive: {
        fileName: 'comparison',
        animations: ['Timeline 1'],
        ratio: 1.2,
      },
      isMultiple: false,
      isRequired: false,
    },

    society: {
      title: 'Inspiring Others',
      subTitle: 'How important is it to you to inspire others or make your loved ones proud?',
      options: [
        '👪 Very important',
        '👏 Important, but my goals matter more',
        '🤔 Somewhat important',
        '😊 Not very important',
        '🙅 Not important at all',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },

    societyInfo: {
      title: 'Fun Fact',
      subTitle: 'Studies show that people who focus on both personal and external motivations, like making loved ones proud, are more likely to succeed.',
      rive: {
        fileName: 'people_group_two',
        animations: [],
        marginTop: '-30%',
        ratio: 1024 / 759,
      },
      isMultiple: false,
      isRequired: false,
    },

    knowledgeMoney: {
      title: highlightText('Knowledge = Money?'),
      subTitle: 'Do you believe your skills and knowledge will lead to higher earnings?',
      options: [
        '💼 Yes, skills = better pay',
        '💸 Probably, not sure how much',
        '🤔 Maybe, but other factors matter more',
        '🙃 No, not really',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    } as Quiz,

    timing: {
      title: `Set your goal on timing`,
      subTitle: 'How much time will you commit to your growth each day?',
      options: [
       '🤙 Easy 5 min/day',
       '👌 Common 10 min/day',
       '🤘 Serious 15 min/day',
       '💪 Intensive 20+ min/day',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    } as Quiz,

    honest: {
      title: `Thanks for being honest!`,
      subTitle: `You are doing great! There are only few steps left`,
      rive: {
        fileName: 'hearts',
        animations: ['Hearts'],
        ratio: 1.75 / 1,
      },
      isMultiple: false,
      isRequired: false,
    },

    result: {
      title: `The quiz is done. Cheers! 👍`,
      message:  `Focusing on personal growth is a meaningful step forward. However, balancing work and personal priorities is a common challenge. Like many people, you sometimes experience stress and struggle with procrastination — and that’s completely normal.
      
      🎧 Using cutting-edge tools and audio for learning is essential nowadays and can significantly enhance your growth.
      
      📈 Your Personal Growth Index (PGI) is currently estimated at 57%. Dedicating just 10–15 minutes a day can boost your PGI from 57% to 93% in three months.

💪 With Peech, you can turn a few minutes a day into real results—and we’ve got a special offer to help you get there faster.`,
      isRequired: true,
      isMultiple: false,
    } as Quiz,

    book1: {
      title: 'Does this book look interesting to you?',
      imageFilePath: defaultBook1.imagePath,
      audioTracks: [{
        title: defaultBook1.title,
        audioPath: defaultBook1.audioPath,
        imagePath: 'assets/img/person3.webp',
      }],
      isVoting: true,
      isMultiple: false,
      isRequired: false,
    } as Quiz,

    book2: {
      title: 'Does this book look interesting to you?',
      imageFilePath: defaultBook2.imagePath,
      audioTracks: [{
        title: defaultBook2.title,
        audioPath: defaultBook2.audioPath,
        imagePath: 'assets/img/person3.webp',
      }],
      isVoting: true,
      isMultiple: false,
      isRequired: false,
    } as Quiz,

    book3: {
      title: 'Does this book look interesting to you?',
      imageFilePath: defaultBook3.imagePath,
      audioTracks: [{
        title: defaultBook3.title,
        audioPath: defaultBook3.audioPath,
        imagePath: 'assets/img/person3.webp',
      }],
      isVoting: true,
      isMultiple: false,
      isRequired: false,
    } as Quiz,


    // format: {
    //   title: 'What text do you want to turn into audio?',
    //   options: [
    //     '📚 Textbooks & Assignments',
    //     '🌐 Web Articles & Blogs',
    //     '📧 Emails & Newsletters',
    //     '📄 Documents & PDFs',
    //     '✍️ My Own Writing, Notes & Memos',
    //     '📖 eBooks',
    //     '📑 Printed Texts',
    //     '💬 Social Media (Facebook, X, Instagram)',
    //     '🤔 Other'
    //   ].map(title => ({ title })),
    //   isMultiple: true,
    //   isRequired: true,
    // },
    // formatInfo: {
    //   title: 'Great choice!',
    //   subTitle: 'We support uploading these types of texts',
    //   topIcon: '🏋️‍♀️',
    //   isRequired: false,
    //   isMultiple: false,
    // },
    // resultInfo: {
    //   title: `${highlightText('87% of Peech users')} have made positive changes in their lives`,
    //   subTitle: 'You\'re already on your way to positive change, too! Let\'s see what you are interested in exactly.',
    //   topIcon: '🤓',
    //   isRequired: false,
    //   isMultiple: false,
    // },
    // speedInfo: {
    //   title: `How about getting it ${highlightText('a bit faster?')}`,
    //   subTitle: 'Let\'s try this on x1.5 and x2 speed. Using speed contol is true productivity boost and one of favorite features among our users',
    //   audioTracks: [audioTracks.medicine, audioTracks.physics],
    //   isRequired: false,
    //   isMultiple: false,
    // },
    // speedStatsInfo: {
    //   title: `With Peech you can save ${highlightText('at least 2 hours')} on reading this week!`,
    //   topIcon: '⏱',
    //   userReview: {
    //     message: 'The app is impressive. easy to listening to, no robotic voice, easy to use. productive use of my driving time. would recommend.',
    //     name: 'Jerin Barnes',
    //     avatarUrl: 'assets/img/avatars/5.png',
    //   },
    //   isRequired: false,
    //   isMultiple: false,
    // },
    // progressInfo: {
    //   title: `Start reading ${highlightText('more in less time')}`,
    //   subTitle: 'Thanks for taking the quiz 👍',
    //   isMultiple: false,
    //   isRequired: false,
    // },
};

