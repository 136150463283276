<div class="start-container d-flex flex-column align-items-center justify-content-center text-center pb-10">
    <div class="content flex-grow-1 d-flex flex-column align-items-center justify-content-center" (click)="continueButtonClicked()">
        <div class="d-flex flex-column align-items-center justify-content-center gap-5">
            <div class="time-pane d-flex align-items-center justify-content-center">
                <i class="ri-time-line"></i>
                <span>2-min quiz</span>
            </div>
            <div class="title">{{title}}</div>
            <div class="subtitle">{{subtitle}}</div>
        </div>

        <div class="flex-grow-1 w-100 d-flex align-items-center">
            <canvas
            #animation
            class="w-100"
            width="1024"
            [height]="1024 / (rive.ratio || 1)"
            ></canvas>
        </div>


        <!-- <div class="main-image image" [ngStyle]="{
            'background-image': 'url(' + mainImagePath + ')'
        }"></div> -->

        <div class="separator">
            <div class="left-line"></div>
            <div class="label">Mentioned in:</div>
            <div class="right-line"></div>
        </div>
        <div class="media-logos image"></div>
    </div>
    <div class="sticky-container mt-5 pb-10">
        <button mat-flat-button class="tagus" (click)="continueButtonClicked()">Get Started</button>
    </div>
    <div class="info gray-color">Florinis 7, Greg Tower, 2nd Floor, 1065, Nicosia, Cyprus</div>
</div>
