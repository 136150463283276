<div class="review light-green-color border-radius p-25 d-flex flex-column justify-content-center">
    <span class="review-stars">●●●●●</span>
    <div class="d-flex content flex-grow-1 mt-15">
        <div class="d-flex">
            <div class="quotas-image mt-10"></div>
        </div>
        <div class="flex-grow-1 d-flex flex-column justify-content-space-between">
            <p class="message">{{data.message}}</p>
            <div class="d-flex justify-content-space-between align-items-center">
                <div class="d-flex flex-column">
                    <strong>{{data.name}}</strong>
                    <div *ngIf="data.title" class="italic title mt-5">{{data.title}}</div>
                </div>
                <img *ngIf="data.avatarUrl" class="avatar" [src]="data.avatarUrl">
            </div>
        </div>
    </div>
    <div *ngIf="data.logoUrl" class="mt-15 d-flex justify-content-center">
        <img class="university-logo" [src]="data.logoUrl">
    </div>
</div>