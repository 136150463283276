import * as amplitude from '@amplitude/analytics-browser';
import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Rive } from '@rive-app/canvas';
import { tap } from 'rxjs';
import { AudioTrack, AudioTrackComponent } from 'src/app/shared/audio-track/audio-track.component';
import { Quiz } from '../../data/quiz';
import { OnboardingFlowService } from '../../services/onboarding-flow.service';

@Component({
  selector: 'app-onboarding-quiz-step',
  templateUrl: './quiz-step.component.html',
  styleUrls: ['./quiz-step.component.scss']
})
export class QuizStepComponent implements OnInit, AfterViewInit {
  data: Quiz;
  form: FormGroup;

  currentIndex: number;
  stepsCount: number;

  isTypingMessagePlayed = false;

  @ViewChildren('audioTrackRef') audioTracks!: QueryList<AudioTrackComponent>;
  @ViewChild('animation') riveAnimation: ElementRef;

  @ViewChild('typingText') typingText: ElementRef;
  @ViewChild('cursor') cursor: ElementRef;

  private riveInstance: Rive | undefined;
  private isAnimationChecked = false;
  private isViewInitted = false;

  private isMessageChecked = false;

  constructor(
    private route: ActivatedRoute,
    private flowService: OnboardingFlowService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.stepsCount = this.flowService.getQuizLength();

    this.route.paramMap
      .pipe(
        tap(params => {
          this.currentIndex = parseInt(params.get('index') || '0', 10);
          this.data = this.flowService.getQuizData(this.currentIndex);

          if (!this.data) {
            this.flowService.goToStart();
            return;
          }

          this.checkAnimation();
          this.checkMessage();

          this.form = this.fb.group({ selectedOptions: [''] });
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.isViewInitted = true;

    if (!this.isAnimationChecked) {
      this.checkAnimation();
    }

    if (!this.isMessageChecked) {
      this.checkMessage();
    }
  }

  buttonClicked(): void {
    const selectedOptions = this.form.value.selectedOptions;

    this.finish(selectedOptions);
  }

  voteClicked(choice: 'yes' | 'no' | 'miss'): void {
    this.finish([choice]);
  }

  optionClicked(): void {
    if (this.data.isMultiple) {
      return;
    }

    this.finish(this.form.value.selectedOptions);
  }

  onPlay(currentTrack: AudioTrackComponent, track: AudioTrack): void {
    amplitude.track('web_audio_demo_played', { title: track.title });

    this.audioTracks.forEach(trackComponent => {
      if (trackComponent !== currentTrack) {
        trackComponent.pause();
      }
    });
    
    this.flowService.rememberLastPlayedTrack(track);
  }

  getBackgroundImageUrl(): string {
    if (this.data.imageFilePath) {
      return `url(${encodeURIComponent(this.data.imageFilePath)})`;
    }

    if (this.data.rive?.backgroundImagePath) {
      return `url(${this.data.rive.backgroundImagePath})`;
    }

    return '';
  }

  private checkMessage() {
    if (!this.isViewInitted) {
      return;
    }

    this.isMessageChecked = true;
    this.isTypingMessagePlayed = false;

    if (!this.data.message) {
      return;
    }

    //this.typingText.nativeElement.innerHTML

    const message = this.data.message;
    const baseSpeed = 2; // Base typing speed in ms
    const wordPauseMin = 300; // Minimum pause after periodic word groups
    const wordPauseMax = 600; // Maximum pause after periodic word groups

    let index = 0;
    let wordCount = 0; // Count words to determine periodic pauses

    const typeAnimation = () => {
      if (index < message.length) {
        const isNewLine = message[index] === '\n';

        this.typingText.nativeElement.innerHTML += isNewLine ? '<br />' :  message[index];
        index++;

        const isSpace = message[index - 1] === " ";
        const typingSpeed = baseSpeed + Math.random() * 50; // Randomized character typing speed

        if (isSpace) {
          wordCount++;
        }

        // Add a longer pause every 3-5 words
        const pause = isNewLine ||
          (wordCount >= Math.floor(Math.random() * 3 + 3) && isSpace)
            ? Math.random() * (wordPauseMax - wordPauseMin) + wordPauseMin
            : typingSpeed;

        if (wordCount >= 3 && isSpace) {
          wordCount = 0; // Reset word count after pause
        }

        setTimeout(typeAnimation, pause);
      } else {
        this.cursor.nativeElement.classList.add('d-none');
        this.isTypingMessagePlayed = true;
      }
    }

    // Start typing animation
    typeAnimation();
  }

  private checkAnimation(): void {
    if (!this.isViewInitted) {
      return;
    }

    this.riveInstance?.stop();
    this.riveInstance?.cleanup();

    if (this.data.rive) {
      const canvas = this.riveAnimation.nativeElement;

      this.riveInstance = new Rive({
        src: `assets/rive/${this.data.rive.fileName}.riv`,
        canvas,
        autoplay: true,
        animations: this.data.rive.animations,
        onLoad: () => this.riveInstance?.resizeToCanvas(), //resizeDrawingSurfaceToCanvas(),
      });
    }

    this.isAnimationChecked = true;
  }

  private finish(options: string[]): void {
    this.flowService.handleFinishedStep(this.currentIndex, options);
  }
}
