<div class="name-container row align-items-center flex-column pb-20">
    <div class="col-12 mt-20 top-section d-flex align-items-center flex-column">
        <div class="text-center">
            <h3>Glad to have you with us!</h3>
            <div class="subtitle mt-20">What is your name?</div>        
        </div>

        <div class="tagus-form-group mt-30 w-100">
            <label class="d-block mb-12 fw-semibold gray-color">Your name <span>*</span></label>
            <mat-form-field appearance="fill">
                <i class="ri-user-3-line"></i>
                <mat-label>Enter your name</mat-label>
                <input #nameInput matInput autocomplete="name" placeholder="Albus" type="text" [formControl]="name" (keyup.enter)="enterPressed()" required>
                <mat-error *ngIf="name.invalid">Name is invalid</mat-error> 
            </mat-form-field>
        </div>

        <button mat-flat-button class="tagus mt-30" [disabled]="name.invalid" (click)="continueButtonClicked()">Continue</button>
    </div>
</div>
