    <div class="row email-container align-items-center flex-column pb-20">
        <div class="col-12 mt-20 top-section d-flex align-items-center flex-column">
            <div class="text-center w-100">
                <h3 [innerHTML]="title"></h3>
                <!-- <div class="subtitle mt-20">{{ subtitle }}</div>         -->
            </div>

            <div class="tagus-form-group mt-30 w-100">
                <mat-form-field appearance="fill">
                    <i class="ri-mail-line"></i>
                    <mat-label>Enter email address</mat-label>
                    <input #emailInput matInput autocomplete="email" placeholder="harry@hogwarts.com" type="email" [formControl]="email" (keyup.enter)="enterPressed()" required>
                    <mat-error *ngIf="email.invalid">Email address is invalid</mat-error> 
                </mat-form-field>

                <span *ngIf="isExistingEmail" class="navigate-to-sign-in-hint">
                    This email is already registered. <span class="navigate-to-sign-in-button" (click)="navigateToSignIn()">Click here to sign in.</span>
                </span>
            </div>

            <button mat-flat-button class="tagus mt-20" [disabled]="email.invalid || isExistingEmail" (click)="continueButtonClicked()">Continue</button>

            <div class="muted-color fs-12 mt-30 text-center">We respect your privacy and we are committed to protecting your personal data.</div>

                <!-- <div class="separator">
        <div class="left-line"></div>
        <div class="or-label">or</div>
        <div class="right-line"></div>
    </div>
    <button class="apple-button" (click)="appleClicked()"><div class="apple-icon"></div>&nbsp;Sign In with Apple</button> -->
        </div>
    </div>
