import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, take, tap } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { CustomizerSettingsService } from 'src/app/services/customizer-settings.service';

enum DiscountTrigger {
  LimitReached = 'limitReached',
  Unsubscribe = 'unsubscribe',
  NotFinishedWebOnboarding = 'webQuizCompletedWithoutSub',
}

@Component({
  selector: 'app-existing-user-discount-paywall',
  templateUrl: './existing-user-discount-paywall.component.html',
  styleUrl: './existing-user-discount-paywall.component.scss'
})
export class ExistingUserDiscountPaywallComponent implements OnInit {
  name = '...';
  email: string | undefined;
  message = '';

  isLoading = true;

  constructor(
    public themeService: CustomizerSettingsService, //TODO: make sure we support dark theme
    private route: ActivatedRoute,
    private api: ApiService,
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.queryParams['id'];
    const email = this.route.snapshot.queryParams['email'] ? decodeURIComponent(this.route.snapshot.queryParams['email']) : undefined;
    const trigger: DiscountTrigger = this.route.snapshot.queryParams['trigger'];

    if (!id && !email) {
      this.name = 'Friend';
      this.isLoading = false;
      return;
    }

    if (trigger === DiscountTrigger.LimitReached || trigger === DiscountTrigger.NotFinishedWebOnboarding) {
      this.message = 'Still deciding? Here is how we can help you:'
    } else if (trigger === DiscountTrigger.Unsubscribe) {
      this.message = 'We welcome you back with this offer:'
    }

    this.fetchUserInfo(id, email);
  }

  private fetchUserInfo(id: string | undefined, email: string | undefined): void {
    if (id) {
      this.api.identifyUser(id)
        .pipe(
          take(1),
          filter(user => !!user),
          tap(user => {
            this.name = user.firstName || user.lastName || user.email || 'Friend';
            this.email = user.email;
            this.isLoading = false;
          }),
        )
        .subscribe();
    } else if (email) {
      this.name = 'Friend';
      this.email = email;
      this.isLoading = false;
    }
  }
}
