import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { UserReview } from 'src/app/shared/user-review/user-review.component';
import { CustomizerSettingsService } from '../../../services/customizer-settings.service';
import { AudioTrack } from '../../../shared/audio-track/audio-track.component';

@Component({
  selector: 'app-education-landing',
  templateUrl: './education-landing.component.html',
  styleUrl: './education-landing.component.scss'
})
export class EducationLandingComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('checkoutElement') checkoutElement: ElementRef;
  @ViewChild('checkout') checkoutComponent: ElementRef;
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const checkoutPosition = this.checkoutComponent.nativeElement.getBoundingClientRect().top;
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    this.isScrollToTopButtonShown = scrollPosition > checkoutPosition;
  }

  slides = [
    'assets/img/universities/1.png',
    'assets/img/universities/2.png',
    'assets/img/universities/4.png',
    'assets/img/universities/5.png',
    'assets/img/universities/3.png',
    'assets/img/universities/6.png',
    'assets/img/universities/7.png',
  ];

  isScrollToTopButtonShown = false;

  purchaseButtonTitle = 'Redeem My Discount';

  trackHistory: AudioTrack = {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/history.mp3',
    title: 'History'
  };

  trackBusiness: AudioTrack = {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/business_management.mp3',
    title: 'Business Management'
  };

  trackMedicine: AudioTrack = {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/clinical_med.mp3',
    title: 'Clinical Medicine'
  };

  trackPhysics: AudioTrack = {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/physics.mp3',
    title: 'Physics'
  };

    reviews: UserReview[] = [
      {
        name: 'Jayati S',
        message: 'Having an app that converts all my reading into voice is just so convenient and amazing',
        avatarUrl: 'assets/img/avatars/1.png'
      },
      {
        name: 'Lauren Fratamico',
        message: 'I always have stacks of papers I want to get through, and it\'s been so nice to finally find an app that allows me to listen to them on the go with a naturalistic voice. Would recommend!',  
        avatarUrl: 'assets/img/avatars/2.png'
      },
      {
        name: 'Mary Ballard-Johansson',
        message: 'What a great app! The reading voice is pleasant and understandable. Even on the harder words. Having the text available to read along helps also. Naturally, as with any new app, there are bugs. But support is readily available. Highly recommend this for anyone doing research or just keeping up with the latest articles.',
        avatarUrl: 'assets/img/person3.webp'
      },
      {
        name: 'AlexP1799',
        message: 'This app is a MUST for anyone in academics. I’ve only had it for a couple of weeks and I’m already sold. I’m in grad school at the moment and almost all of our reading are academic articles. It takes me ages to get through them every week and by the end it feels like my eyes are going to fall out of my head.',
        avatarUrl: 'assets/img/avatars/3.png'
      },
      {
        name: 'Watson Chiwambo',
        message: 'Amazing app to help you study especially if you don\'t have much time due to work, family and other things.',
        avatarUrl: 'assets/img/avatars/4.png'
      },
      {
        name: 'Jenny0987',
        message: 'This app is such a great concept! Makes it easy to do everything I want to do all in one. Great way to multi task. This app would be so useful with school. It can read to you while you’re doing work!',
        avatarUrl: 'assets/img/avatars/7.png'
      },
      {
        name: 'Erin Barnes',
        message: 'The app is impressive. easy to listening to, no robotic voice, easy to use. productive use of my driving time. would recommend.',
        avatarUrl: 'assets/img/avatars/5.png'
      },
      {
        name: 'Alisson X',
        message: 'It is the perfect application to study without having to stop doing your things, I put my headphones on and it saves me a lot of time. Totally recommended!',
        avatarUrl: 'assets/img/avatars/6.png',
      }
    ];

  review0: UserReview = {
    name: 'Jayati S.',
    avatarUrl: 'assets/img/avatars/8.png',
    message: 'Having an app that converts all my reading into voice is just so convenient and amazing'
  };

  review1: UserReview = {
    name: 'Anna V.',
    avatarUrl: 'assets/img/avatars/1.png',
    message: 'I\'m really picky about AI voices, but this one was amazing! Even at 1.5x speed, it was engaging to listen to. It\'s a game changer to be able to listen while walking!'
  };

  private observer: IntersectionObserver;
  private isEventTriggered = false;

  constructor(
    public themeService: CustomizerSettingsService, //TODO: make sure we support dark theme
    private eventsService: EventsService,
  ) {}

  ngOnInit(): void {
    //this.eventsService.track('page_view');
  }

  ngAfterViewInit(): void {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.triggerVisibilityEvent();
        }
      });
    }, { threshold: 0.1 }); // Adjust threshold as needed

    this.observer.observe(this.checkoutElement.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  checkoutClicked() {
    this.checkoutComponent.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  private triggerVisibilityEvent() {
    if (this.isEventTriggered) {
      return;
    }

    this.eventsService.track('paywall_opened');
    //track('web_payment_checkout_view');

    this.isEventTriggered = true;
  }
}
