import * as amplitude from '@amplitude/analytics-browser';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ApiService } from 'src/app/services/api.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { OnboardingFlowService } from '../../services/onboarding-flow.service';

@Component({
  selector: 'app-android',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatButtonModule, MatInputModule, SharedModule],
  templateUrl: './android.component.html',
  styleUrl: './android.component.scss'
})
export class AndroidComponent implements OnInit {
  @ViewChild('emailInput') emailInput!: ElementRef;

  email = new FormControl('', [Validators.required, Validators.pattern(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i)]);

  title = `We're sorry, but Android support is not available yet`;
  subtitle = `We’re working hard on it! Leave your email address, and we’ll let you know as soon as it’s ready.`;

  constructor(
    private api: ApiService,
    private flowService: OnboardingFlowService,
  ) {}

  ngOnInit(): void {
    amplitude.track({
      event_type: 'web_android_email_view',
      user_properties: {
        web_user_platform: 'android',
      }
    });
  }

  enterPressed(): void {
    if (!this.email.value || this.email.invalid) {
      return;
    }

    this.emailInput.nativeElement.blur();

    this.proceed();
  }

  continueButtonClicked(): void {
    if (this.email.invalid) {
      return;
    }

    this.proceed();
  }

  private proceed() {
    this.flowService.handleFinishedAndroidEmail(this.email.value!)
  }
}
