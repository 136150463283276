import { Injectable } from '@angular/core';
import { StatsigClient } from '@statsig/js-client';
import { environment } from 'src/environments/environment';

//NOTE: this is used for our AB tests

interface TestingProps {
  placementNumber: 1 | 2,
}

@Injectable({
  providedIn: 'root'
})
export class StatsIGService {
  private properties: TestingProps = {
    placementNumber: 1,
  };

  private initialized = false;
  private client: StatsigClient;

  constructor() {
    this.client = new StatsigClient(
      'client-T5698fFDyjPAgSGO1ROSaKP1nwzaAHEQRDSyWScLlQg', { 
        appVersion: environment.appVersion,
      }, { 
        environment: { 
          tier: environment.production ? 'production' : 'development'
        }
      }
    );
  }

  async initializeStatsig() {
    await this.client.initializeAsync();

    this.initialized = true;
  }

  setConfigProperty(props: Partial<TestingProps>) {
    this.properties = {
      ...this.properties,
      ...props,
    };
  }

  getTestingProps(): TestingProps {
    return {...this.properties};
  }

  getExperiment(name: 'result_screen_before_paywall' | 'pricing_test_1' | 'login_ui') {
    return this.client.getExperiment(name);
  }

  checkGate(gateName: string): boolean {
    if (!this.initialized) {
      console.error('Statsig has not been initialized');
      return false;
    }

    return this.client.checkGate(gateName);
  }

  getLayer(layerName: string) {
    if (!this.initialized) {
      console.error('Statsig has not been initialized');
      return null;
    }

    return this.client.getLayer(layerName);
  }

  logEvent(name: string) {
    this.client.logEvent(name);
  }
}
