import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import WaveSurfer from 'wavesurfer.js';

export interface AudioTrack {
  title: string;
  imagePath: string;
  audioPath: string;
}

@Component({
  selector: 'app-audio-track',
  templateUrl: './audio-track.component.html',
  styleUrl: './audio-track.component.scss'
})
export class AudioTrackComponent implements AfterViewInit, OnDestroy {
  @ViewChild('waveform') waveformEl: ElementRef;

  @Input() data: AudioTrack;

  @Output() onPlayed = new EventEmitter<AudioTrackComponent>();

  wavesurfer: WaveSurfer;
  isPlaying = false;

  ngAfterViewInit(): void {
    this.wavesurfer = WaveSurfer.create({
      container: this.waveformEl.nativeElement,
      height: 28,
      barWidth: 2,
      barGap: 2,
      barRadius: 2,
      cursorWidth: 0,
      waveColor: '#bdbec0',
      progressColor: '#31BC6D',
      cursorColor: 'navy',
      url: this.data.audioPath,
    });

    this.wavesurfer.on('play', () => { 
      this.isPlaying = true;
      this.onPlayed.emit(this);
    });

    this.wavesurfer.on('pause', () => this.isPlaying = false);
    this.wavesurfer.on('finish', () => this.isPlaying = false);
  }

  ngOnDestroy(): void {
    //TODO: unsibscribe from the events as well
    if (this.wavesurfer) {
      this.wavesurfer.destroy();
    }
  }

  playPause(): void {
    this.wavesurfer.playPause();
  }

  pause(): void {
    this.wavesurfer.pause();
  }
}
