import * as amplitude from '@amplitude/analytics-browser';
import { track } from '@amplitude/analytics-browser';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, interval, take, takeWhile, tap } from 'rxjs';
import { Placement } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { EventsService } from 'src/app/services/events.service';
import { AudioTrack, AudioTrackComponent } from 'src/app/shared/audio-track/audio-track.component';
import { CheckoutComponent, ProductView } from 'src/app/shared/checkout/checkout.component';
import { ModalComponent, ModalData } from 'src/app/shared/modal/modal.component';
import { UserReview } from 'src/app/shared/user-review/user-review.component';
import { OnboardingFlowService } from '../../services/onboarding-flow.service';
import { StatsIGService } from '../../services/stats-ig.service';

interface Placements {
  main: Placement;
  discount: Placement;
}

@Component({
  selector: 'app-onboarding-paywall',
  templateUrl: './paywall.component.html',
  styleUrls: ['./paywall.component.scss']
})
export class PaywallComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('topCheckout') topCheckout: ElementRef;
  @ViewChildren('checkout') checkoutComponents: QueryList<CheckoutComponent>;
  @ViewChildren('audioTrackRef') audioTracks!: QueryList<AudioTrackComponent>;

  selectedProduct: ProductView;

  discountTitle: string = '60%';
  initialDiscountTitle: string = '';
  timer: string = '10:00';

  email: string | undefined;
  name: string | undefined;
  stripeCustomerId: string | undefined;

  purchaseButtonTitle = 'Continue';

  tracksInterests: AudioTrack[];
  tracksGrowth: AudioTrack[];
  trackLanguage: AudioTrack;

  placement: Placement;

  reviews: UserReview[];

  readonly placements: Placements;

  private isActive: boolean = true; //to kill the subscriptions

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private flowService: OnboardingFlowService, 
    private abService: StatsIGService,
    private pixel: EventsService) {
      const abPlacementNumber = this.abService.getTestingProps().placementNumber;

      this.placements = {
        main: abPlacementNumber === 2 ? 'growth_quiz_2' : 'growth_quiz',
        discount: abPlacementNumber === 2 ? 'growth_quiz_discount_2' : 'growth_quiz_discount',
      };
    }

  ngOnInit(): void {
    const { growth, interests, language } = this.flowService.getUserAudioTracks();

    this.trackLanguage = language;
    this.tracksInterests = interests;
    this.tracksGrowth = growth;

    this.reviews = this.flowService.getReviews();

    this.stripeCustomerId = this.flowService.getStripeCustomerId();
    this.name = this.flowService.getName();

    this.placement = this.placements.main;

    this.authService.user$.pipe(
      take(1),
      tap(user => this.email = user?.email || undefined),
    ).subscribe();    
  }

  ngAfterViewInit(): void {
    this.startTimer();

    this.pixel.track('paywall_opened');
    track('web_paywall_view');
  }

  ngOnDestroy(): void {
    this.isActive = false;
  }

  payButtonClicked(): void {
    this.checkoutComponents.first.purchaseButtonClicked();
  }

  topButtonClicked(): void {
    //TODO: actually check for not only the first checkout on the page but for all of them
    if (this.isElementAtLeastVisible(this.topCheckout.nativeElement)) {
      this.payButtonClicked();
    } else {
      this.scrollToCheckout();
    }
  }

  handleDiscountTitle(title: string) {
    this.discountTitle = title;

    if (!this.initialDiscountTitle) {
      this.initialDiscountTitle = title;
    }
  }

  handlePurchaseButtonTitle(title: string) {
    this.purchaseButtonTitle = title;
  }

  getInsufficientFundsAction(): (() => void) | undefined {
    if (this.placement === this.placements.discount) {
      //nothing to offer already
      return;
    }

    return () => {
      //TODO: maybe show another animation in this case
      //TODO: maybe introduce placement with cheapest plans possible for this case
      this.switchToDiscount(`We see that there are insufficient funds, but at Peech we are trying to support as many people as possible. That's why we are offering you an additional discount`);
    };
  }

  onPlay(currentTrack: AudioTrackComponent): void {
    amplitude.track('web_audio_demo_played', { title: currentTrack.data.title });
    
    this.audioTracks.forEach(trackComponent => {
      if (trackComponent !== currentTrack) {
        trackComponent.pause();
      }
    });
  }

  onPurchaseCanceled() {
    if (this.placement === this.placements.discount) {
      //nothing to offer more
      return;
    }

    this.switchToDiscount('We want you to succeed, which is why we are offering you an additional discount');
  }

  private scrollToCheckout() {
    this.topCheckout.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  private switchToDiscount(message: string) {
    this.dialog.open<ModalComponent, ModalData>(ModalComponent, {
      data: {
        title: 'Special Discount Unlocked',
        message,
        okButtonTitle: 'Get My Discount',
        rive: {
          fileName: 'gift_box',
          animations: ['Timeline 1'],
          ratio: 1.1 / 1,
        },
      }
    })
      .afterClosed()
      .pipe(
        tap(() => {
          this.placement = this.placements.discount;
          amplitude.track('web_discount_shown');
          this.scrollToCheckout();
        }),
      )
      .subscribe();
  }

  private startTimer(): void {
    const secs = 10 * 60;

    interval(1000)
      .pipe(
        filter(passedSecs => passedSecs > 0),
        tap((passedSecs) => {
          const leftSecs = 60 - (passedSecs % 60);
          const leftSecsLabel = leftSecs > 9 ? `${leftSecs}` : `0${leftSecs}`;
          const minsLeft = Math.floor((secs - passedSecs) / 60);

          this.timer = `${minsLeft}:${leftSecsLabel}`;
        }),
        takeWhile(() => this.isActive),
      )
      .subscribe();
  }

  private isElementAtLeastVisible(el: HTMLElement): boolean {
    const threshold = 0.7; // 70% by default 
    const rect = el.getBoundingClientRect();

    // Viewport dimensions
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;

    // Element dimensions
    const elHeight = rect.height;
    const elWidth = rect.width;
    
    // If the element is entirely out of the viewport in any dimension, return false quickly
    if (rect.bottom < 0 || rect.top > windowHeight || rect.right < 0 || rect.left > windowWidth) {
      return false;
    }

    // Calculate the intersection coordinates
    const intersectionTop = Math.max(rect.top, 0);
    const intersectionBottom = Math.min(rect.bottom, windowHeight);
    const intersectionLeft = Math.max(rect.left, 0);
    const intersectionRight = Math.min(rect.right, windowWidth);

    // Intersection width & height
    const intersectionWidth = intersectionRight - intersectionLeft;
    const intersectionHeight = intersectionBottom - intersectionTop;

    // If there's no intersection area, it's not visible
    if (intersectionWidth <= 0 || intersectionHeight <= 0) {
      return false;
    }

    // Intersection area vs. total element area
    const intersectionArea = intersectionWidth * intersectionHeight;
    const elArea = elWidth * elHeight;
    
    // Compare ratio to threshold
    const ratio = intersectionArea / elArea;
    return ratio >= threshold;
  }
}
