<header
    class="header-area bg-white border-bottom-radius box-shadow mb-25"
    [class.component-dark-theme]="themeService.isDark()"
>
    <div class="d-flex align-items-center justify-content-space-between">
        <div class="d-flex align-items-center">
            <div class="logo" *ngIf="!isLogoAvatar"></div>
            <span class="name ms-10" *ngIf="!isLogoAvatar">Peech</span>
        </div>
        <div class="header-right-side d-flex align-items-center mt-0 mb-0">
            <button [matMenuTriggerFor]="profileMenu" class="profile-btn main-color border-none bg-transparent position-relative d-flex align-items-center">
                <img *ngIf="isLoggedIn" src="{{avatarUrl}}" class="rounded-circle" alt="user">
                <div *ngIf="!isLoggedIn" class="user-icon ri-user-3-fill"></div>
                <span *ngIf="isLoggedIn" class="fw-semibold">{{ name }}</span>
            </button>
            <mat-menu class="profile-menu-dropdown" #profileMenu="matMenu" xPosition="before">
                <ul class="m-0">
                    <li *ngIf="!isLoggedIn">
                        <a routerLink="/authentication/login">
                            <i class="ri-user-3-line"></i> Log In
                        </a>
                    </li>
                    <li *ngIf="isLoggedIn">
                        <a routerLink="/account">
                            <i class="ri-user-3-line"></i> Profile
                        </a>
                    </li>
                    <li *ngIf="isLoggedIn">
                        <a routerLink="/authentication/logout">
                            <i class="ri-shut-down-line"></i> Logout
                        </a>
                    </li>
                </ul>
            </mat-menu>
        </div>
    </div>
</header>