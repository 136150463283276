import * as amplitude from '@amplitude/analytics-browser';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AudioTrack } from 'src/app/shared/audio-track/audio-track.component';
import { UserReview } from 'src/app/shared/user-review/user-review.component';
import { Goal, highlightText, Interest, JobChallenge, Quiz, quizSteps, RiveAnimationConfig } from '../data/quiz';
import { StatsIGService } from './stats-ig.service';
import { interestTrackMap, languageTracks } from '../data/sounds';
import { challengesBookMap, goalsBooksMap } from '../data/books';
import { goalIndexSourceMap } from '../data/goals-index-map';

export enum QuizTopic {
  femaleInteresting = 'female_interesting',
  femaleMother = 'female_mother',
  female = 'female',
  femaleGirl = 'female_girl',
  male = 'male',
  general = 'general',
  religion = 'religion',
}

enum ConsumptionTime {
  morning,
  commute,
  workingOut,
  break,
}

enum Gender {
  male,
  female,
  other,
}

export type QuizStepKey = keyof typeof quizSteps;

//TODO: run AB with it 
const eyesScreenTimeSteps: QuizStepKey[] = [
  'eyes',
  'eyesBreak',

  'screenTime',
  'screenTimeSocialProof',
  'screenTimeQuestion',
  'screenTimeResult',
  'screenTimeImprovement', 
]

const stepsOrder: QuizStepKey[] = [
  'age',
  'gender',
  'amountInfo',
  'level',

  'device',
  
  'goal',
  'goalInfo',

  'lackOfKnowledge',
  'lackOfKnowledgeInfo',

  'jobChallenges',

  'book1',

  'balance',

  'retention',
  'retentionInfo',
  'anxiety',

  'procrastination',

  'interests',
  'interestsInfo',

  'technology',
  'technologyInfo',

  'audio',
  'audioInfo',

  'relaxTime',
  'relaxTimeGoal',

  'language',
  'languageInfo',

  'time',

  'duration',
  'durationInfo',

  'book2',
  'book3',

  'society',
  'societyInfo',

  'timing',
  
  'honest', //here we go to customization step and //after this step we have login
  'result',
];

type QuizSteps = {
  [K in QuizStepKey]: Quiz;
};

@Injectable({
  providedIn: 'root'
})
export class OnboardingFlowService {
  private topic: QuizTopic;

  private steps: QuizSteps = quizSteps;

  private times: ConsumptionTime[] = [];

  private gender: Gender | null = null;
  private age: string;
  private goal: Goal | string;
  private interests: Interest[] = [];
  private lastPlayedTrack: AudioTrack | null = null;

  private jobChallenges: JobChallenge[] = [];

  private deviceType: string;

  private name: string | undefined;
  private stripeCustomerId: string | undefined;

  private graphAnimationName = 'Timeline 2';

  private lostTimeYear = '76 days';
  private lostTimeLife = '12 years';
  private recoveredTimeLife = '5 years+';

  private secondLanguage: keyof typeof languageTracks = 'es';

  //this is to device whether to show some of the next screens:
  private eyesStrainFeeling: string;
  private screenTimeSatisfaction: string;
  private lackOfKnowledgeFeeling: string;


  private readinessToUseTech: string;
  private audioLearningExperience: string;
  private anxietyFeeling: string;
  private procrastinationFrequency: string;

  private quizStepOrder = stepsOrder;

  constructor(
    private router: Router, 
    //private pixel: EventsService, 
    private abTestService: StatsIGService) {}

  goToStart() {
    this.router.navigate(['onboarding']);
  }

  setTopic(topic: QuizTopic | null) {
    if (topic) {
      this.topic = topic;
    } else {
      this.topic = QuizTopic.general;
    }

    if ([QuizTopic.male].includes(this.topic)) {
      this.gender = Gender.male;
    } else if ([QuizTopic.female, QuizTopic.femaleInteresting, QuizTopic.femaleGirl, QuizTopic.femaleMother].includes(this.topic)) {
      this.gender = Gender.female;
    }
  }

  getTopic(): QuizTopic {
    return this.topic;
  }

  handleFinishedIntroScreen() {
    amplitude.track('web_intro_screen_viewed');
    this.router.navigate(['onboarding', 'quiz', 0]);
  }

  handleFinishedCustomization() {
    //const indexToGo = this.quizStepOrder.indexOf('result');

    amplitude.track('web_customization_screen_viewed');

    //this.router.navigate(['onboarding', 'quiz', indexToGo]);
    this.router.navigate(['onboarding', 'login']);
  }

  handleFinishedLogin(stripeCustomerId: string) {
    const indexToGo = this.quizStepOrder.indexOf('result');
    
    this.stripeCustomerId = stripeCustomerId;
    //event of login is fired in auth service
    
    this.router.navigate(['onboarding', 'quiz', indexToGo]);
  }

  handleFinishedName(name: string) {
    const indexToGo = this.quizStepOrder.indexOf('society');

    this.name = name;

    amplitude.track('web_name_entered', { name }); //TODO: maybe set name to user prop instead 
    
    this.router.navigate(['onboarding', 'quiz', indexToGo]);
  }

  handleFinishedManualGoal(goal: string) {
    const indexToGo = this.quizStepOrder.indexOf('goalInfo');

    this.goal = goal;

    amplitude.track('web_goal_entered', { goal });
    
    this.router.navigate(['onboarding', 'quiz', indexToGo]);
  }

  handleFinishedAndroidEmail(email: string) {
    amplitude.track('web_android_email_entered', { email });
    
    this.router.navigate(['onboarding', 'waiting-list']);
  }

  handleFinishedStep(index: number, options: string[]) {
     const key = this.quizStepOrder[index];
     const title = this.steps[key].title;

     this.trackResponse(key, options, title);
     this.saveParameters(key, options);
     
     if (index + 1 >= this.quizStepOrder.length) {
      this.router.navigate(['onboarding', 'checkout']);
     } else if (key === 'honest') {
      this.router.navigate(['onboarding', 'ready']);
     } else if (key === 'goal' && this.goal === '🤔 Other') {
      this.router.navigate(['onboarding', 'goal']);
     } else if (key === 'device' && this.deviceType === 'Android') {
      this.router.navigate(['onboarding', 'android']);
     } else {
      this.router.navigate(['onboarding', 'quiz', index + 1]);
     }
  }

  getName(): string | undefined {
    return this.name;
  }

  getStripeCustomerId(): string | undefined {
    return this.stripeCustomerId;
  }

  getQuizData(index: number): Quiz {
    const key = this.quizStepOrder[index];

    if(this.shouldSkipStep(key) && index < this.getQuizLength() - 1) {
      this.router.navigate(['onboarding', 'quiz', index + 1]);
    }

    return this.customizeStepData(key) || this.steps[key];
  }

  getQuizLength(): number {
    return this.quizStepOrder.length;
  }

  rememberLastPlayedTrack(audio: AudioTrack): void {
    this.lastPlayedTrack = audio; // not used atm
  }

  getUserAudioTracks(): { growth: AudioTrack[], interests: AudioTrack[], language: AudioTrack } {
    const language = languageTracks[this.secondLanguage] || languageTracks.es;

    const interests = this.interests.length > 0 
      ? this.interests.flatMap(interest => interestTrackMap[interest] ?? interestTrackMap['🔍 Thriller & Mystery'])
      : [interestTrackMap['🔍 Thriller & Mystery']];

    const growthGoals = this.goal 
      ? goalsBooksMap[this.goal as Goal] ?? goalsBooksMap['🤔 Other']
      : goalsBooksMap['🤔 Other'];

    const growthChallenges = this.jobChallenges.length > 0 
      ? this.jobChallenges.flatMap(challenge => challengesBookMap[challenge] ?? challengesBookMap['✏️ Other'])
      : [challengesBookMap['✏️ Other']];

    const growth = [...growthGoals, ...growthChallenges]
      .map(book => ({
        title: book.title,
        audioPath: book.audioPath,
        imagePath: book.imagePath,
      }));

    return { language, interests, growth };
  }

  getReviews(): UserReview[] {
    switch (this.goal) {
      case '🏠 Buy a house':
        return [
          {
            name: 'Stephanie Ward, 51',
            message: `I’ve been living in a rental for over a decade and finally decided it was time to own my place. This app turned my long commute into a crash course on mortgages and saving strategies. Now, I’m more confident than ever about buying a house in the next year!`,
            avatarUrl: 'assets/img/avatars/w2.jpg',
          },
          {
            name: 'Alan Dixon, 48',
            message: 'I felt lost when it came to interest rates and home inspections. By listening to The ABCs of Real Estate Investing by Ken McElroy on this app, I finally understood the basics. It broke everything down in plain English. Highly recommend it to anyone feeling stuck!',
            avatarUrl: 'assets/img/avatars/m3.jpg',
          },
        ];
      case '👩‍💼 Getting a promotion':
        return [
          {
            name: 'Thomas Brooks, 46',
            message: 'I was worried I’d never move up at my age, but I started listening to short leadership tips on this app during my lunch break. Within a few months, my boss noticed a real difference in how I handled projects, and I finally got that promotion!',
            avatarUrl: 'assets/img/avatars/m3.jpg',
          },
          {
            name: 'Maria Sorenson, 52',
            message: 'I’d been at my company for 15 years without any major advancement. I tried listening to Harvard Business Review articles on this app for fresh ideas on team management. It really helped me stand out at work, and I was promoted soon after.',
            avatarUrl: 'assets/img/avatars/w2.jpg',
          },
        ];
      case '💸 Pay the Debt':
        return [
          {
            name: 'Monica Davis, 57',
            message: 'My credit card bills were piling up, and I was losing sleep. Listening to personal finance tips on this app changed my attitude toward budgeting. I started using the debt snowball method, and now I can finally see the light at the end of the tunnel.',
            avatarUrl: 'assets/img/avatars/w2.jpg',
          },
          {
            name: 'Robert Leung, 50',
            message: 'As a self-employed contractor, I struggled to keep my finances in order. This app introduced me to Dave Ramsey’s Total Money Makeover, which was a real eye-opener. I’ve already paid off two credit cards and feel more optimistic about my future.',
            avatarUrl: 'assets/img/avatars/m3.jpg',
          },
        ];
      case '📊 Starting my own business':
        return [
          {
            name: 'Rita Campbell, 56',
            message: 'I’d dreamed of running a home bakery for years. Hearing real entrepreneur success stories on this app gave me the motivation to actually start. I’ve launched my bakery from home, and next month I’m moving into a small storefront!',
            avatarUrl: 'assets/img/avatars/w2.jpg',
          },
          {
            name: 'David Prescott, 61',
            message: 'I was close to retiring but still wanted to open a consulting firm. Listening to The E-Myth Revisited by Michael E. Gerber through this app highlighted all the pitfalls I might face. It saved me from some major blunders early on.',
            avatarUrl: 'assets/img/avatars/m3.jpg',
          },
        ];
      case '👩‍❤️‍👩 Relationship commitment':
        return [
          {
            name: 'John Harris, 47',
            message: 'For a while, I was unsure about marriage. This app’s relationship advice made me realize how crucial open communication is. Now, I’ve taken that leap and feel much more confident about our future.',
            avatarUrl: 'assets/img/avatars/m3.jpg',
          },
          {
            name: 'Kelly Donovan, 48',
            message: 'My partner and I had been together for eight years with no clear direction. I finally tried Men Are from Mars, Women Are from Venus in audio form, and it really changed how I view our differences. We’ve decided to move in together, and it feels great.',
            avatarUrl: 'assets/img/avatars/w2.jpg',
          },
        ];
      case '👨‍👩‍👧 Parenthood':
        return [
          {
            name: 'Michael Zimmerman, 50',
            message: 'My wife and I recently became grandparents, and I wanted to brush up on modern parenting tips. This app let me listen to short child development articles while doing chores. Now I actually have something helpful to share with my daughter!',
            avatarUrl: 'assets/img/avatars/m3.jpg',
          },
          {
            name: 'Dr. Fiona Clarke, 58',
            message: 'I’m a retired pediatrician, but I like staying current on childcare research for my grandkids. Thanks to this app, I caught up on the latest Parents magazine articles, and it’s wonderful feeling so informed again.',
            avatarUrl: 'assets/img/avatars/w2.jpg',
          },
        ];
      case '🌍 Travel the World':
        return [
          {
            name: 'Ernest Cook, 60',
            message: 'I’ve always dreamed of exploring Asia, but the planning stressed me out. This app turned Lonely Planet guides into quick audio sessions that made everything seem more accessible. I’m finally booking a trip to Southeast Asia for next spring!',
            avatarUrl: 'assets/img/avatars/m3.jpg',
          },
          {
            name: 'Sabrina Miller, 49',
            message: 'I was nervous about traveling solo at my age. Then I listened to stories from others my age who had the courage to see the world on their own. It gave me the push I needed—now I’m off to Europe this summer!',
            avatarUrl: 'assets/img/avatars/w2.jpg',
          },
        ];
      case '🏖 Retirement planning':
        return [
          {
            name: 'Helen Brewer, 59',
            message: 'Retirement felt like a distant dream until it crept up on me. I’m not great with financial jargon, but this app made learning about 401(k)s and IRAs so much easier. I adjusted my contributions and feel a lot more secure about my future now.',
            avatarUrl: 'assets/img/avatars/w2.jpg',
          },
          {
            name: 'Charles Ingram, 60',
            message: 'My friend told me about The Simple Path to Wealth by JL Collins, so I tried the audio version on this app. It explained index funds in a way I could finally grasp. Rearranging my investments was simpler than I expected—and a lot more rewarding!',
            avatarUrl: 'assets/img/avatars/m3.jpg',
          },
        ];
      case '🤯 Mental and emotional well-being':
        return [
          {
            name: 'Douglas Phillips, 50',
            message: 'I’ve always been a worrier, and stress was eating me alive. Through this app, I found mindfulness talks I could listen to on my morning jog. Now, I’m noticeably calmer and even sleeping better.',
            avatarUrl: 'assets/img/avatars/m3.jpg',
          },
          {
            name: 'Alicia Green, 54',
            message: 'I’ve tried therapy off and on, but daily stresses still got to me. A coworker recommended Mindfulness for Beginners by Jon Kabat-Zinn, so I listened to the audio. It felt like having a personal coach for relaxation. My anxiety’s finally under control.',
            avatarUrl: 'assets/img/avatars/w2.jpg',
          },
        ];
      case '💪 Improve health and fitness':
        return [
          {
            name: 'Evelyn Robinson, 49',
            message: 'I never found time to read about healthy living. With this app, I listened to short nutritional articles while doing the laundry. Slowly but surely, I changed my eating habits and even added a daily walk. It’s amazing how these small steps add up.',
            avatarUrl: 'assets/img/avatars/w2.jpg',
          },
          {
            name: 'Dr. Leonard Brown, 57',
            message: 'I’m a physician, but ironically, I neglected my own health. Listening to Younger Next Year on this app made me realize how vital it is to stay active. Now I take a brisk walk every day and feel more energetic at work.',
            avatarUrl: 'assets/img/avatars/m3.jpg',
          },
        ];
      case '🎓 Get the best education':
        return [
          {
            name: 'Sophia Lee, 22',
            message: 'I used to struggle with retaining what I read in textbooks. Now, I listen to my course materials on the way to class or during gym sessions. It’s incredible how much more I remember and how much time I save.',
            avatarUrl: 'assets/img/avatars/girl1.jpg',
          },
          {
            name: 'Dr. Michael Carter, 45',
            message: 'As an educator, I believe learning should fit seamlessly into our lives. This app allows me to explore academic articles and research while commuting or taking a walk. It’s transformed how I consume and retain information.',
            avatarUrl: 'assets/img/avatars/m3.jpg',
          },
        ];
      default:
        return [
          {
            name: 'Raymond Holt, 55',
            message: 'I was feeling stuck without a clear direction. One day, I listened to a piece about uncovering personal passions. It sparked a desire to pick up painting—something I’d left behind in my 20s. I’ve rediscovered a whole new side of myself.',
            avatarUrl: 'assets/img/avatars/m3.jpg',
          },
          {
            name: 'Nicole Patterson, 47',
            message: 'I’m transitioning careers and had no idea where to start. Hearing short bios of people who reinvented themselves after 45 gave me hope. It’s incredible to learn it’s never too late for a fresh start.',
            avatarUrl: 'assets/img/avatars/w2.jpg',
          },
        ];
    }

        // {
        //   name: 'Alena Mango',
        //   avatarUrl: 'assets/img/avatars/woman1.jpg',
        //   message: `I’m preparing for my first marathon and spend hours training every week. Before Peech, I struggled to keep up with my readings. Now, I listen to my lectures while running, so I never lose time and my retention has improved dramatically!`
        // },
        // {
        //   name: 'Justin Geidt',
        //   avatarUrl: 'assets/img/avatars/boy1.jpg',
        //   message: `Balancing my engineering workload and personal life felt impossible before Peech. Now, I use it daily to turn my technical readings into audio, and I’ve gained so much extra time for myself.`
        // },
        // {
        //   name: 'Jezebel Levin',
        //   avatarUrl: 'assets/img/avatars/girl1.jpg',
        //   message: `As a doctor, there’s so much to read every day. Peech helped me convert my textbooks into audio, which allowed me to study even while doing errands. My productivity has never been higher!`
        // },
  }

  private getDeviceType(): 'ios' | 'android' | 'unknown' {
    const ua = navigator.userAgent.toLowerCase();

    if (/iphone|ipad|ipod/.test(ua)) {
      return 'ios';
    }

    if (/android/.test(ua)) {
      return 'android';
    }

    return 'unknown';
  }

  private customizeStepData(key: QuizStepKey): Quiz | undefined {
    switch (key) {
      // case 'age':
      //   if ([QuizTopic.male].includes(this.topic)) {
      //     return {
      //       ...this.steps.age,
      //       //title: `Start reading smarter—rule your day like a king`,
      //       //subTitle: `Turn any text into audio fueling growth, confidence, and success. Choose your age:`,
      //       options: [
      //         {
      //           title: '< 21',
      //           imageFilePath: 'assets/onboarding/pictures/man_age_1.png',
      //           imageMode: 'cover',
      //         },
      //         {
      //           title: '21 - 34',
      //           imageFilePath: 'assets/onboarding/pictures/man_age_2.png',
      //           imageMode: 'cover',
      //         },
      //         {
      //           title: '35 - 44',
      //           imageFilePath: 'assets/onboarding/pictures/man_age_3.png',
      //           imageMode: 'cover',
      //         },
      //         {
      //           title: '45+',
      //           imageFilePath: 'assets/onboarding/pictures/man_age_4.png',
      //           imageMode: 'cover',
      //         },
      //       ]
      //     }
      //   }

      //   return {
      //     ...this.steps.age,
      //   }
      case 'amountInfo':
        const countOptions = ['800K', '900K', '1 Million'];
        const randomCount = countOptions[Math.floor(Math.random() * countOptions.length)];
        let animationName = 'both';
        let title = 'Individuals';

        if (this.gender === Gender.male) {
          title = 'Male Individuals';
          animationName = 'male';
        } else if (this.gender === Gender.female) {
          title = 'Female Individuals';
          animationName = 'female';
        }

        return {
          ...this.steps.amountInfo,
          // rive: {
          //   ...this.steps.amountInfo.rive as RiveAnimationConfig,
          //   animations: ['appear', animationName], //["idle","female","both","male","appear"]
          // },
          title: `Join ${highlightText(`Over ${randomCount} ${title}`)} Your Age Worldwide`,
        };

      case 'screenTimeResult':
        return {
          ...this.steps.screenTimeResult,
          title: `The bad news: You're on track to spend ${highlightText(this.lostTimeYear, true)} on your phone this year. Over your lifetime, this adds up to:`,
          headline: this.lostTimeLife,
          subTitle: `Think about all the valuable time you could reclaim for things that truly matter`,
        }

      case 'screenTimeImprovement':
        return {
          ...this.steps.screenTimeImprovement,
          headline: this.recoveredTimeLife,
        };

      case 'result':
        return {
          ...this.steps.result,
          message: this.getPersonalizedInsight(),
        };

      case 'languageInfo':
        return {
          ...this.steps.languageInfo,
          audioTracks: [this.getUserAudioTracks().language]
        };

      case 'book1':
        const book = challengesBookMap[this.jobChallenges[0] as JobChallenge] || challengesBookMap['✏️ Other'];

        return {
          ...this.steps.book1,
          imageFilePath: book.imagePath,
          audioTracks: [{
            title: book.title,
            audioPath: book.audioPath,
            imagePath: book.narrator === 'female' ? 'assets/img/person3.webp' : 'assets/img/person4.webp',
          }],
        };
  

      case 'book2':
        const book2 = goalsBooksMap[this.goal as Goal]?.[0] || goalsBooksMap['🤔 Other'][0];

        return {
          ...this.steps.book2,
          imageFilePath: book2.imagePath,
          audioTracks: [{
            title: book2.title,
            audioPath: book2.audioPath,
            imagePath: book2.narrator === 'female' ? 'assets/img/person3.webp' : 'assets/img/person4.webp',
          }],
        };

      case 'book3':
        const book3 = goalsBooksMap[this.goal as Goal]?.[1] || goalsBooksMap['🤔 Other'][1];

        return {
          ...this.steps.book3,
          imageFilePath: book3.imagePath,
          audioTracks: [{
            title: book3.title,
            audioPath: book3.audioPath,
            imagePath: book3.narrator === 'female' ? 'assets/img/person3.webp' : 'assets/img/person4.webp',
          }],
        };

      default:
        return;
    }
  }

  private shouldSkipStep(key: QuizStepKey): boolean {
    switch(key) {
      case 'gender':
        return this.gender !== null;
      case 'eyesBreak':
        return ['Rarely','Never'].includes(this.eyesStrainFeeling);
      case 'screenTimeSocialProof':
        return ['Very satisfied', 'Somewhat satisfied'].includes(this.screenTimeSatisfaction);
      case 'lackOfKnowledgeInfo':
        return ['Rarely','Never'].includes(this.lackOfKnowledgeFeeling);
      case 'goalInfo':
        return ['🤔 Other'].includes(this.goal);
      case 'device':
        return this.getDeviceType() === 'ios';
      default:
        return false;
    }
  }

  private saveParameters(currentStepKey: QuizStepKey, selectedOptions: string[]) {
    switch (currentStepKey) {
      case 'gender':
        if (selectedOptions[0].includes('Male')) {
          this.gender = Gender.male;
        } else if (selectedOptions[0].includes('Female')) {
          this.gender = Gender.female;
        } else {
          this.gender = Gender.other;
        }
        break;
      case 'age':
        this.age = selectedOptions[0];
        break;
      case 'device':
        this.deviceType = selectedOptions[0];
        break;
      case 'goal':
        this.goal = selectedOptions[0] as Goal;
        break;
      case 'eyes':
        this.eyesStrainFeeling = this.removeEmojis(selectedOptions[0]);
        break;
      case 'lackOfKnowledge':
        this.lackOfKnowledgeFeeling = this.removeEmojis(selectedOptions[0]);
        break;
      case 'screenTime':
        this.screenTimeSatisfaction = this.removeEmojis(selectedOptions[0]);
        break;
      case 'language':
        switch (selectedOptions[0]) {
          case 'Spanish':
            this.secondLanguage = 'es';
            break;
          case 'French':
            this.secondLanguage = 'fr';
            break;
          case 'German':
            this.secondLanguage = 'de';
            break;
          case 'Italian':
            this.secondLanguage = 'it';
            break;
          case 'Portuguese':
            this.secondLanguage = 'pt';
            break;
          case 'Chinese':
            this.secondLanguage = 'zh';
            break;
          case 'Hindi':
            this.secondLanguage = 'hi';
            break;
          default:
            this.secondLanguage = 'es';
            break;
        }
        break;
      case 'screenTimeQuestion':
        const yearsMultiplicator = 62; //left to live
        const saveCoef = 0.4;
        let hoursMultiplicator = 4;

        switch (selectedOptions[0]) {
          case '1-2 hours':
            hoursMultiplicator = 1.5;
            break;
          case '2-3 hours':
            hoursMultiplicator = 2.5;
            break;
          case '3-5 hours':
            hoursMultiplicator = 4;
            break;
          case '5-8 hours':
            hoursMultiplicator = 6.5;
            break;
          case '8-12 hours':
            hoursMultiplicator = 10;
            break;
          case '12+ hours':
            hoursMultiplicator = 13;
            break;
        }

        const daysCount = Math.ceil(hoursMultiplicator * 365 / 24);
        const yearsCount =  Math.ceil(daysCount * yearsMultiplicator / 365);
        const recoveredYears = Math.ceil(saveCoef * yearsCount);

        this.lostTimeYear = daysCount > 1 ? `${daysCount} days` : '1 day';
        this.lostTimeLife = yearsCount > 1 ? `${yearsCount} years` : '1 year';
        this.recoveredTimeLife = recoveredYears > 1 ? `${recoveredYears} years+` : `1 year+`;
        break;
      case 'interests':
        this.interests = selectedOptions as Interest[];
        break;
      case 'audio': 
        this.audioLearningExperience = selectedOptions[0];
        break;
      case 'technology': 
        this.readinessToUseTech = selectedOptions[0];
        break;
      case 'anxiety':
        this.anxietyFeeling = selectedOptions[0];
        break;
      case 'procrastination':
        this.procrastinationFrequency = selectedOptions[0];
        break;
      case 'jobChallenges':
        this.jobChallenges = selectedOptions as JobChallenge[];
        break;
    }
  }

  private getUserReview(): UserReview {
    const review = {
      name: 'Jerin Barnes',
      avatarUrl: 'assets/img/avatars/5.png',
    }

    return {
      ...review,
      message: 'Peech has given me the edge in my work, helping me absorb concepts on the go.',
    };
  }

  private trackResponse(currentStepKey: QuizStepKey, options: string[], title: string): void {
    amplitude.track('web_quiz_step_finished', { key: currentStepKey, options, title: this.stripHTMLTags(title) });
  }

  private getPersonalizedInsight(): string {
    const goal = this.goal;
    const jobChallenges = this.jobChallenges;
    const age = this.age;
    const gender = this.gender ?? Gender.male;
    const readinessToUseTech = this.readinessToUseTech;
    const audioLearningExperience = this.audioLearningExperience;
    const anxietyFeeling = this.anxietyFeeling;
    const procrastinationFrequency = this.procrastinationFrequency;

    const mainChallenge = jobChallenges.find(x => !['✏️ Other', '❌ I\'m not currently working', '😎 Everything is perfect'].includes(x)) || '';
  
    const hasPositiveTech = ['🤩 Very excited and open', '😊 Open, but cautious'].includes(readinessToUseTech);
    const hasPositiveAudio = ['🎧 Yes, regularly', '🎙️ Yes, occasionally', '👂 No, but I’m interested'].includes(audioLearningExperience);
  
    let technologyOrAudioText = 'Using cutting-edge tools and audio for learning is essential nowadays and can significantly enhance your growth.';

    if (hasPositiveTech && hasPositiveAudio) {
        technologyOrAudioText = `You’re already open to new technologies and have used audio in your learning — that’s fantastic!`;
    } else if (hasPositiveAudio && !hasPositiveTech) {
        technologyOrAudioText = `You’ve already used audio in your learning, but you’re not fully confident about new technologies yet.`;
    } else if (hasPositiveTech && !hasPositiveAudio) {
        technologyOrAudioText = `You’re open to exploring new technologies, but you haven’t used audio tools for learning yet.`;
    }

    const hasAnxiety = ['😰 Yes, it’s significant', '😟 Yes, to some extent', '😬 Occasionally'].includes(anxietyFeeling);
    const hasProcrastination = ['🐌 Always', '🐢 Frequently', '🐇 Sometimes'].includes(procrastinationFrequency);

    let stressAndProcrastinationText = ''
    if (hasAnxiety && hasProcrastination) {
      stressAndProcrastinationText = 'Like many people, you sometimes experience stress and struggle with procrastination — and that’s completely normal.';
    } else if (hasAnxiety) {
      stressAndProcrastinationText = 'You sometimes feel stressed like many people, and that’s perfectly normal.';
    } else if (hasProcrastination) {
      stressAndProcrastinationText = 'Sometimes struggling with procrastination is something many people face, and it’s completely normal.';
    }

    const { indexName, indexAbbreviation, nameSynonym: goalSynonym, source } = goalIndexSourceMap[goal as Goal] || goalIndexSourceMap['🤔 Other'];
    const fullIndexName = `${indexName} (${indexAbbreviation})`;
  
    const currentProgress = 57;
    const potentialProgress = 93;
  
    const ageRange = age ? `${this.normalizeAnswer(age)} age group` : "general population";
    const genderLabel = gender === Gender.male ? "men" : gender === Gender.female ? "women" : "individuals";
    const progressCalculation = `Your ${fullIndexName} is based on data for ${genderLabel} in the ${ageRange}.`;
  
    const challengeText = mainChallenge
    ? `However, work challenges like ${this.normalizeAnswer(mainChallenge)} can sometimes make it harder to focus on personal goals.`
    : `However, balancing work and personal goals is a common challenge.`;
  
    const dailyProgressHook = `Dedicating just 10-15 minutes a day can boost your ${indexAbbreviation} from ${currentProgress}% to ${potentialProgress}% in three months, according to ${source}.`;
  
    return `Working to ${goalSynonym} is a meaningful step forward. ${challengeText} ${stressAndProcrastinationText}

      🎧 ${technologyOrAudioText}

      📈 ${progressCalculation} It’s currently at ${currentProgress}%. ${dailyProgressHook}

      💪 With Peech, you can turn a few minutes a day into real progress - and we’ve got a special offer to help you get there faster.`;
  }

  private normalizeAnswer(answer: string | undefined): string {
    return this.removeEmojis(answer || '')
    .toLowerCase()
    .replace(/\bmy\b/gi, 'your')
    .trim()
  }

  //todo: use maybe emoji-regex npm
  private removeEmojis(string: string): string {
    return string.replace(/[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1FA70}-\u{1FAFF}\u{2300}-\u{23FF}\u{200D}\u{1F1E6}-\u{1F1FF}\u{1F004}\u{1F0CF}\u{1F18E}\u{1F191}-\u{1F251}\u{1F300}-\u{1F321}\u{1F324}-\u{1F393}\u{1F396}-\u{1F397}\u{1F399}-\u{1F39B}\u{1F39E}-\u{1F3F0}\u{1F3F3}-\u{1F3F5}\u{1F3F7}-\u{1F4FD}\u{1F4FF}-\u{1F53D}\u{1F549}-\u{1F54E}\u{1F550}-\u{1F567}\u{1F56F}-\u{1F570}\u{1F573}-\u{1F579}\u{1F57A}\u{1F587}\u{1F58A}-\u{1F58D}\u{1F590}\u{1F5A5}\u{1F5A8}\u{1F5B1}-\u{1F5B2}\u{1F5BC}\u{1F5C2}-\u{1F5C4}\u{1F5D1}-\u{1F5D3}\u{1F5DC}-\u{1F5DE}\u{1F5E1}\u{1F5E3}\u{1F5EF}\u{1F5F3}\u{1F5FA}-\u{1F64F}\u{1F680}-\u{1F6C5}\u{1F6CB}-\u{1F6CF}\u{1F6D0}-\u{1F6D2}\u{1F6E0}-\u{1F6E5}\u{1F6E9}\u{1F6EB}-\u{1F6EC}\u{1F6F0}-\u{1F6F3}\u{1F6F4}-\u{1F6F9}\u{1F910}-\u{1F93E}\u{1F940}-\u{1F970}\u{1F973}-\u{1F976}\u{1F97A}-\u{1F9A2}\u{1F9B0}-\u{1F9B9}\u{1F9C0}-\u{1F9C2}\u{1F9D0}-\u{1F9FF}\u{1FA70}-\u{1FAFF}\u{200D}]/gu, '').trim();
  }

  private stripHTMLTags(input: string): string {
    return input.replace(/<[^>]*>/g, '');
  }
}
