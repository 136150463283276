import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { EmailComponent } from './components/email/email.component';
import { GoalInputComponent } from './components/goal-input/goal-input.component';
import { NameComponent } from './components/name/name.component';
import { PaywallComponent } from './components/paywall/paywall.component';
import { PreparationComponent } from './components/preparation/preparation.component';
import { QuizListOptionComponent } from './components/quiz-list-option/quiz-list-option.component';
import { QuizStepComponent } from './components/quiz-step/quiz-step.component';
import { SignInWithEmailComponent } from './components/sign-in-with-email/sign-in-with-email.component';
import { StartComponent } from './components/start/start.component';
import { OnboardingRootComponent } from './onboarding-root.component';
import { OnboardingRoutingModule } from './onboarding-routing.module';
import { StatsIGService } from './services/stats-ig.service';

export function initializeABTestService(statsigService: StatsIGService) {
  return (): Promise<void> => {
    return statsigService.initializeStatsig();
  };
}

@NgModule({
  declarations: [
    PaywallComponent,
    SignInWithEmailComponent,
    //FailureComponent,
    PreparationComponent,
    QuizStepComponent,
    QuizListOptionComponent,
    StartComponent,
    GoalInputComponent,
    //SuccessComponent,
    NameComponent,
    EmailComponent,
    OnboardingRootComponent,
  ],
  imports: [
    CommonModule,
    OnboardingRoutingModule,
    ReactiveFormsModule,
    MatListModule,
    MatChipsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    FormsModule,
    RouterModule,
    SharedModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeABTestService,
      deps: [StatsIGService],
      multi: true,
    },
  ],
})
export class OnboardingModule { }
