import { track } from '@amplitude/analytics-browser';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { filter, finalize, map, mergeMap, ReplaySubject, take, tap } from 'rxjs';
import { ApiService, BillingPeriod, Placement, Product } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { PaymentComponent, PaymentData } from '../payment/payment.component';

export interface ProductView extends Product {
  title: string;
  fullPriceLabel: string;
  priceLabel: string;
  fullDailyPriceLabel: string;
  dailyPriceLabel?: string;
  isBestDeal: boolean;
  discountLabel?: string;
  trialLabel?: string;
  //infoLabel: string;
}

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss'
})
export class CheckoutComponent implements OnInit, OnChanges {
  @Input() placement: Placement;
  @Input() email: string | undefined;
  @Input() stripeCustomerId: string | undefined;
  @Input() name: string | undefined;
  @Input() insufficientFundsAction: (() => void) | undefined;

  @Input() isDisclaimerShown: boolean = true;

  @Input() selectedProduct: Product;
  @Output() selectedProductChange = new EventEmitter<Product>();

  @Output() discountTitle = new EventEmitter<string>();
  @Output() purchaseButtonTitle = new EventEmitter<string>();
  @Output() canceledPurchase = new EventEmitter<void>();

  currentPurchaseButtonTitle: string = 'Continue';
  
  products: ProductView[] = [];

  isLoading: boolean = true;

  private productsReady$ = new ReplaySubject<void>(1);

  constructor(
    private api: ApiService,
    private bottomSheet: MatBottomSheet,
    private pixel: EventsService,
  ) {}

  ngOnInit(): void {
    this.purchaseButtonTitle.emit(this.currentPurchaseButtonTitle);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['placement'] && changes['placement'].currentValue !== changes['placement'].previousValue) {
      this.loadProducts();
    }
  }

  loadProducts() {
    this.isLoading = true;

    this.api.getProducts(this.placement)
      .pipe(
        take(1),
        map(products => products.map(product => this.mapProductView(product))),
        tap(products => this.products = products),
        map(() => this.getDefaultSelectedProduct()),
        tap(product => this.setSelectedProduct(product)),
        tap(() => this.markMostPopularProduct()),
        tap(() => this.productsReady$.next()),
        finalize(() => this.isLoading = false),
      )
      .subscribe();
  }

  purchaseButtonClicked() {
    this.productsReady$
      .pipe(
        take(1),
        mergeMap(() => this.openPaymentSheet()),
      )
      .subscribe();
  }

  openPaymentSheet() {
    return this.bottomSheet.open(PaymentComponent, {
      data: {
        product: this.selectedProduct,
        email: this.email,
        stripeCustomerId: this.stripeCustomerId,
        name: this.name,
        buttonTitle: this.currentPurchaseButtonTitle,
        insufficientFundsAction: this.insufficientFundsAction,
        isDisclaimerShown: this.isDisclaimerShown,
      } as PaymentData,
      panelClass: 'payment-sheet',
    })
    .afterDismissed()
    .pipe(
      filter((isSubscribed: boolean) => !isSubscribed),
      tap(() => this.canceledPurchase.emit()),
    )
  }

  productClicked(product: ProductView) {
    this.setSelectedProduct(product);
  }

  private setSelectedProduct(product: ProductView) {
    this.selectedProductChange.emit(product);
    this.selectedProduct = product;
    this.setPurchaseButtonTitle();
    this.discountTitle.emit(product.discountLabel);
  }

  private setPurchaseButtonTitle() {
    //TODO: add the logic here to check if it is free trial, of there is a discount etc
    if (this.selectedProduct.trialDays > 0) {
      this.currentPurchaseButtonTitle = `Try ${this.selectedProduct.trialDays}-Day Trial`;
    } else {
      this.currentPurchaseButtonTitle = 'Continue';
    }

    this.purchaseButtonTitle.emit(this.currentPurchaseButtonTitle);
  }

  private getDefaultSelectedProduct() {
    let bestProduct;

    if (this.products.length === 1) {
      bestProduct = this.products[0];
    } else {
      const defaultProduct = this.products.find(product => product.isDefault);

      if (defaultProduct) {
        bestProduct = defaultProduct;
      } else {
        bestProduct = this.products.sort((p1, p2) => p1.billingPeriodDays - p2.billingPeriodDays)[0];
      }
    }

    return bestProduct;
  }

  private markMostPopularProduct() {
    const bestProduct = this.products.find(product => product.trialDays) || this.products.sort((p1, p2) => p1.billingPeriodDays - p2.billingPeriodDays)[0];

    bestProduct.isBestDeal = true;
  }

  private mapProductView(product: Product): ProductView {
    const discountLabel = product.discount ? `${product.discount.amount}%` : '';
    const trialLabel = product.trialDays ? `${product.trialDays} Days Trial` : '';

    return {
      ...product,
      isBestDeal: false,
      title: this.getProductTitle(product),
      priceLabel: `$${product.introductoryPriceUsd || product.setupFeePriceUsd || product.recurringPriceUsd}`,
      fullPriceLabel: `$${product.recurringPriceUsd}`,
      fullDailyPriceLabel: `$${product.dailyPriceUsd}`,
      dailyPriceLabel: `$${product.dailyIntroductoryPriceUsd || product.dailyPriceUsd }`.replace('.', ','),
      discountLabel,
      trialLabel,
    };
  }

  private getProductTitle(product: Product): string {
    switch (product.billingPeriod) {
      case BillingPeriod.Month:
        return '1 Month Plan';
      case BillingPeriod.Quarter:
        return '3 Months Plan';
      case BillingPeriod.Year:
        return '12 Months Plan';
      case BillingPeriod.SemiYear:
        return '6 Months Plan';
      case BillingPeriod.Week:
        return '1 Week Plan';
      default:
        return 'Premium Plan';
    }

    // if (product.trialDays > 0) {
    //   return `${title}, ${product.trialDays} Days Free Trial`;
    // }
  }
}
