import { track } from '@amplitude/analytics-browser';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { loadStripe, PaymentIntentResult, PaymentRequest, Stripe, StripeElements, StripeError } from '@stripe/stripe-js';
import { combineLatest, from, fromEvent, map, switchMap, take, tap } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from 'src/app/services/events.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ModalComponent, ModalData } from 'src/app/shared/modal/modal.component';
import { environment } from 'src/environments/environment';
import { ProductView } from '../checkout/checkout.component';

export interface PaymentData {
  product: ProductView;
  buttonTitle: string;
  email?: string;
  name?: string;
  stripeCustomerId?: string;
  isDisclaimerShown: boolean;
  insufficientFundsAction?: () => void;
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss'
})
export class PaymentComponent implements OnInit, AfterViewInit {
  //@ViewChild('paypal') paypalElement!: ElementRef;
  @ViewChild('stripeElement') stripeElement!: ElementRef;
  @ViewChild('stripeForm') stripeForm!: ElementRef;
  @ViewChild('stripeEmail') stripeEmail!: ElementRef;

  @ViewChild('expressCheckout') expressCheckoutContainer!: ElementRef;

  isLoading: boolean = true;

  email: string | undefined;
  name: string | undefined;
  product: ProductView;

  isExpressMethodsAvailable: boolean = true;
  purchaseButtonTitle = 'Continue';

  isDisclaimerShown: boolean;

  totalLabel: string;

  private stripeCustomerId: string | undefined;
  private clientSecret: string; //stripe

  private stripeElements: StripeElements | undefined;
  private stripeLoaded$ = from(loadStripe(environment.stripePubKey));

  private insufficientFundsAction: (() => void) | undefined;

  private resultUrl = 'payment-result';

  constructor(
    private router: Router,
    private api: ApiService,
    private pixel: EventsService,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private bottomSheetRef: MatBottomSheetRef<PaymentComponent, boolean>,
    @Inject(MAT_BOTTOM_SHEET_DATA) data: PaymentData,
    ) {
      this.email = data.email;
      this.product = data.product;
      this.purchaseButtonTitle= data.buttonTitle;
      this.name = data.name;
      this.stripeCustomerId = data.stripeCustomerId;
      this.insufficientFundsAction = data.insufficientFundsAction;
      this.isDisclaimerShown = data.isDisclaimerShown;

      if (this.router.url.includes('onboarding')) {
        this.resultUrl = 'onboarding/payment-result';
      }
    }

  ngOnInit(): void {
    const subscription$ = this.api.createStripeSubscription(this.product.id, this.email, this.stripeCustomerId, this.name).pipe(
      tap(({ clientSecret }) => {
        this.clientSecret = clientSecret;
      }),
    );

    combineLatest([subscription$, this.stripeLoaded$])
      .pipe(
        take(1),
        map(([_, stripe]) => stripe),
        tap((stripe) => from(this.createCardForm(stripe!))),
        switchMap((stripe) => from(this.createQuickMethodForm(stripe!))),
        tap(() => this.isLoading = false),
        switchMap((stripe) => this.watchStripe(stripe)),
      )
      .subscribe();

    this.calculateTotal();
  }

  ngAfterViewInit(): void {
    track('web_checkout_opened');
    this.pixel.track('checkout_opened');
  }

  close(isSuccess: boolean) {
    this.bottomSheetRef.dismiss(isSuccess);
  }

  private calculateTotal() {
    this.totalLabel = this.product.priceLabel;
  }

  private async createCardForm(stripe: Stripe) {
    this.stripeElements = stripe.elements({
      clientSecret: this.clientSecret,
      appearance: {
        theme: 'stripe',
        variables: {
          //colorBackground: '#4E4238',
          //colorText: 'var(--white100)',
          fontFamily: `'Poppins', sans-serif`,
          //colorPrimary: '#FDF3EC8C',
          //colorTextPlaceholder: '#FDF3EC8C'
        },
      },
      loader: 'always'
    });

    this.stripeElements?.create('payment', {
      paymentMethodOrder: ['card', 'paypal'], //'apple_pay', 'google_pay', 
      wallets: {
        applePay: 'never',
        googlePay: 'never',
      },
      layout: 'accordion',
    }).mount(this.stripeElement.nativeElement);

    if (!this.email) {
      this.stripeElements?.create('linkAuthentication').mount(this.stripeEmail.nativeElement);
    }
  }

  private async createQuickMethodForm(stripe: Stripe) {
    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Total',
        amount: Math.round(100 * (this.product.setupFeePriceUsd || this.product.introductoryPriceUsd || this.product.recurringPriceUsd)),
      },
      requestShipping: false,
      requestPayerName: false, //TODO: maybe make it true
      requestPayerEmail: !this.email,
    });
    const applePayElement = this.stripeElements?.create('paymentRequestButton', {
      paymentRequest,
      style: {
        paymentRequestButton: {
          //type: 'subscribe',
          height: '48px',
          buttonSpacing: '8px',
        }
      }
    });

    const result = await paymentRequest.canMakePayment();

    if (result) {
      applePayElement?.mount(this.expressCheckoutContainer.nativeElement);
      this.trackApplePay(stripe, paymentRequest);
    } else {
      this.isExpressMethodsAvailable = false;
    }

    return stripe;
  }

  private trackApplePay(stripe: Stripe, paymentRequest: PaymentRequest) {
    paymentRequest.on('paymentmethod', async (event) => {
      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
        this.clientSecret,
        { payment_method: event.paymentMethod.id },
        { handleActions: false }
      );
    
      if (confirmError) {
        event.complete('fail');
        this.handleError(confirmError);
      } else {
        event.complete('success');

        if (paymentIntent.status === "requires_action") {
          const { error } = await stripe.confirmCardPayment(this.clientSecret);

          if (error) {
            this.handleError(error);
          } else {
            this.goToSuccess();
          }
        } else {
          this.goToSuccess();
        }
      }
    });
  }

  private watchStripe(stripe: Stripe) {
    return fromEvent(this.stripeForm.nativeElement, 'submit')
      .pipe(
        tap((event: any) => event.preventDefault()),
        tap(() => this.loadingService.start()),
        switchMap(() => from(stripe.confirmPayment({
          elements: this.stripeElements!,
          confirmParams: {
            return_url: `${location.origin}/${this.resultUrl}?product_code=${this.product.code}`
          },
          redirect: 'if_required' as 'if_required'
        }))),
        tap((result: PaymentIntentResult) => {
          this.loadingService.finish();

          if (result.error) {
            return this.handleError(result.error);
          }
  
          console.log(result.paymentIntent);
    
          this.goToSuccess();
        }),
      );
  }

  private goToSuccess() {
    this.loadingService.finish();
    this.close(true);
    this.router.navigate([this.resultUrl], {
      queryParams: {
        product_code: this.product.code,
      }
    });
  }

  private handleError(error: StripeError) {  
    console.log(error);

    this.loadingService.finish();

    if (error.type === 'validation_error') {
      return;
    }

    track('web_subscribe_failure', {
      error: error.code || error.message
    });

    if (error.decline_code === 'insufficient_funds' && this.insufficientFundsAction) {
      this.close(true); //attention here
      this.insufficientFundsAction();
      return;
    }

    this.stripeElements?.getElement('card')?.clear();

    //TODO: in case of insufficient funds propose discount
    this.dialog.open<ModalComponent, ModalData>(ModalComponent, {
      data: {
        title: 'Sorry',
        message: error.message || 'Your payment failed. Please Try Again.',
        rive: {
          fileName: 'credit_card',
          animations: ['Timeline 1'],
          ratio: 1.1 / 1,
        },
      }
    })
      .afterClosed()
      .subscribe();
  }
}
