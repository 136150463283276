<div class="login-container d-flex flex-column align-items-center justify-content-center w-100">
    <div class="login-picture"></div>
    <div class="title">Sign In to Peech</div>
    <div class="subtitle">We need you to Sign In to be able to save the web pages to your library</div>
    <button class="email-button mt-15 w-100" (click)="emailClicked()">Sign In with Email</button>
    <button class="apple-button w-100" (click)="loginClicked('apple')"><div class="apple-icon"></div>&nbsp;Sign In with Apple</button>
    <div class="alternative-label">Or</div>
    <div class="buttons-container w-100">
      <button class="facebook-button" (click)="loginClicked('facebook')"></button>
      <button class="google-button" (click)="loginClicked('google')"></button>
    </div>
</div>
