import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, take, tap } from 'rxjs';
import { AuthProviderType, AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ModalComponent, ModalData } from 'src/app/shared/modal/modal.component';

export enum LoginTarget {
  chromeExtension = 'chrome-extension',
  //onboarding = 'onboarding',
  account = 'account',
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private target: LoginTarget | 'unknown' = 'unknown';

  constructor(
    private authService: AuthService, 
    private router: Router, 
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    ) {}

  ngOnInit(): void {
    this.target = this.route.snapshot.queryParams['source'] || this.route.snapshot.queryParams['target'] || 'unknown';
  }

  emailClicked(): void {
    this.router.navigate(['authentication', 'email'], { 
      queryParams: {
        target: this.target,
      }
     });
  }

  loginClicked(provider: AuthProviderType): void {
    this.loadingService.start();

    this.authService.login(provider)
      .pipe(
        take(1),
        finalize(() => this.loadingService.finish()),
        tap(user => {
          if (this.target === 'chrome-extension') {
            this.router.navigate(['success'], {
              queryParams: {
                destination: 'peech-chrome-extension', //TODO: change it in chrome extension's code base
                token: user.refreshToken,
              }
            })
          } else {
            this.router.navigate(['/account']);
          }
        }),
        catchError((error) => { 
          console.log(error);

          return this.dialog.open<ModalComponent, ModalData>(ModalComponent, {
            data: {
              title: 'Sorry',
              message: 'Failed to Sign You In. Please try again.'
            }
          }).afterClosed();
        }))
      .subscribe();
  }
}
