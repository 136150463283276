import * as amplitude from '@amplitude/analytics-browser';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, switchMap, take, tap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { EventsService } from 'src/app/services/events.service';

//failed:
//https://staging.app.getpeech.com/result?payment_intent=pi_3QkPagGzd0JjFWBK0pjM0DfR&payment_intent_client_secret=pi_3QkPagGzd0JjFWBK0pjM0DfR_secret_7rWsyUAP5PjUBSkCgg84vwfGI&redirect_status=failed

//success:
//https://staging.app.getpeech.com/result?payment_intent=pi_3QkPbYGzd0JjFWBK15iJK19c&payment_intent_client_secret=pi_3QkPbYGzd0JjFWBK15iJK19c_secret_uHwXesVezjIWsUJ0OjXuIR3ZZ&redirect_status=succeeded

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrl: './payment-result.component.scss'
})
export class PaymentResultComponent implements OnInit {
  isSuccess = true;

  checkoutUrl = '/onboarding/checkout';

  constructor(private route: ActivatedRoute, private router: Router, private pixel: EventsService, private auth: AuthService) {}

  ngOnInit(): void {
    this.isSuccess = this.route.snapshot.queryParamMap.get('redirect_status') !== 'failed';

    const product_code = this.route.snapshot.queryParamMap.get('product_code');

    if (!this.router.url.includes('onboarding')) {
      this.checkoutUrl = '/user-discount'; //TODO: think on how to handle this better in order to restore the url to where the user came from
    }

    this.auth.authInitialized$
      .pipe(
        filter(initted => initted),
        take(1),
        switchMap(() => this.auth.user$),
        take(1),
        tap(user => {
          if (this.isSuccess) {
            amplitude.track('web_subscribe_success', { product_code }); //it is initted only in scope of onboarding
            this.pixel.track('subscribe', user?.email);
          } else {
            amplitude.track('web_subscribe_failure', { product_code });
          }
        }),
      ).subscribe();
  }
}
