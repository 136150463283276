<div *ngIf="option.emoji" class="icon">{{ option.emoji }}</div>
<div *ngIf="option.imageFilePath" class="image w-100" [ngStyle]="{ 
    'background-image': 'url(' + option.imageFilePath + ')',
    'background-size': option.imageMode === 'cover' ? 'cover' : 'contain',
    'background-position': option.imageMode === 'cover' ? 'top' : 'center',
    'height': option.imageMode === 'cover' ? '160px' : '130px',
    }"></div>
<canvas
    *ngIf="option.rive"
    #animation
    class="w-100" 
    width="480"
    [height]="480 / option.rive.ratio"
    >
</canvas>
<div *ngIf="option.title" class="title">{{ option.title }}</div>
