import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, tap } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ModalComponent, ModalData } from 'src/app/shared/modal/modal.component';
import { LoginTarget } from '../login/login.component';

@Component({
  selector: 'app-email-login',
  templateUrl: './email-login.component.html',
  styleUrl: './email-login.component.scss'
})
export class EmailLoginComponent implements OnInit {
  @ViewChild('emailInput') emailInput!: ElementRef;

  email = new FormControl('', [Validators.required, Validators.pattern(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i)]);
  
  title = `Enter your Email`;
  subtitle = `We'll send a magic link to log in`;

  private target: LoginTarget = LoginTarget.account;

  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    const target = this.route.snapshot.queryParams['target'] as LoginTarget;

    if (target) {
      this.target = target;
    }
  }

  enterPressed(): void {
    if (!this.email.value || this.email.invalid) {
      return;
    }

    this.emailInput.nativeElement.blur();

    this.loginEmail();
  }

  continueButtonClicked(): void {
    if (!this.email.value || this.email.invalid) {
      return;
    }

    this.loginEmail();
  }

  private loginEmail(): void {
    this.loadingService.start();

    this.api.sendEmailLoginLink(this.email.value!, `${location.origin}/authentication/link?target=${this.target}`)
      .pipe(
        tap(() => this.loadingService.finish()),
        tap(() => this.router.navigate(['authentication', 'link-info'])),
        catchError((error) => {
          console.log(error);

          return this.showError();
        }),
      )
      .subscribe();
  }

  private showError() {
    this.loadingService.finish();
    
    return this.dialog.open<ModalComponent, ModalData>(ModalComponent, {
      data: {
        title: 'Sorry',
        message: 'Failed to Sign You In. Please try again.',
      }
    })
    .afterClosed()
  }
}
