import { Goal, JobChallenge } from "./quiz";

export interface Book {
  title: string;
  author: string;
  imagePath: string;
  audioPath: string;
  narrator: 'male' | 'female';
}

// { [key: string]: Book } 
const books = {
    randomWalkDownWallStreet: {
        title: 'A Random Walk Down Wall Street',
        author: 'Burton G. Malkiel',
        imagePath: 'assets/onboarding/books/A Random Walk Down Wall Street.jpg',
        audioPath: 'assets/onboarding/sounds/A_Random_Walk_Down_Wall_Street.mp3',
        narrator: 'male',
    },

    theSimplePathToWealth: {
        title: 'The Simple Path to Wealth',
        author: 'JL Collins',
        imagePath: 'assets/onboarding/books/The Simple Path to Wealth.jpg',
        audioPath: 'assets/onboarding/sounds/The_Simple_Path_to_Wealth_by_JL_Collins.mp3',
        narrator: 'female',
    },

    theTotalMoneyMakeover: {
        title: 'The Total Money Makeover',
        author: 'Dave Ramsey',
        imagePath: 'assets/onboarding/books/The Total Money Makeover.jpg',
        audioPath: 'assets/onboarding/sounds/The_Total_Money_Makeover_by_Dave_Ramsey.mp3',
        narrator: 'female',
    },

    theLeanStartup: {
        title: 'The Lean Startup',
        author: 'Eric Ries',
        imagePath: 'assets/onboarding/books/The Lean Startup.jpg',
        audioPath: 'assets/onboarding/sounds/The_Lean_Startup_by_Eric_Ries.mp3',
        narrator: 'female',
    },

    theMillionaireRealEstateInvestor: {
        title: 'The Millionaire Real Estate Investor',
        author: 'Gary Keller',
        imagePath: 'assets/onboarding/books/The Millionaire Real Estate Investo.jpg',
        audioPath: 'assets/onboarding/sounds/The_Millionaire_Real_Estate_Investor.mp3',
        narrator: 'female',
    },

    the7HabitsOfHighlyEffectivePeople: {
        title: 'The 7 Habits of Highly Effective People',
        author: 'Stephen R. Covey',
        imagePath: 'assets/onboarding/books/The 7 Habits of Highly Effective People.jpg',
        audioPath: 'assets/onboarding/sounds/The_7_Habits_of_Highly_Effective_People.mp3',
        narrator: 'female',
    },

    thePsychologyOfMoney: {
        title: 'The Psychology of Money',
        author: 'Morgan Housel',
        imagePath: 'assets/onboarding/books/The Psychology of Money.jpg',
        audioPath: 'assets/onboarding/sounds/The_Psychology_of_Money.mp3',
        narrator: 'male',
    },

    theWarOfArt: {
        title: 'The War of Art',
        author: 'Steven Pressfield',
        imagePath: 'assets/onboarding/books/The War of Art.jpg',
        audioPath: 'assets/onboarding/sounds/The_War_of_Art.mp3',
        narrator: 'male',
    },

    burnout: {
        title: 'Burnout',
        author: 'Emily Nagoski and Amelia Nagoski',
        imagePath: 'assets/onboarding/books/Burnout The Secret to Unlocking the Stress Cycle.jpg',
        audioPath: 'assets/onboarding/sounds/Burnout_The_Secret_to_Unlocking_the_Stress_Cycle.mp3',
        narrator: 'female',
    },

    radicalCandor: {
        title: 'Radical Candor',
        author: 'Kim Scott',
        imagePath: 'assets/onboarding/books/Radical Candor Be a Kick-Ass Boss Without Losing Your Humanity.jpg',
        audioPath: 'assets/onboarding/sounds/Radical_Candor_Be_a_Kick-Ass_Boss_Without_Losing_Your_Humanity.mp3',
        narrator: 'male',
    },

    richDadPoorDad: {
        title: 'Rich Dad Poor Dad',
        author: 'Robert T. Kiyosaki',
        imagePath: 'assets/onboarding/books/Rich Dad Poor Dad.jpg',
        audioPath: 'assets/onboarding/sounds/Rich_Dad_Poor_Dad_by_Robert_T._Kiyosaki.mp3',
        narrator: 'male',
    },

    drive: {
        title: 'Drive',
        author: 'Daniel H. Pink',
        imagePath: 'assets/onboarding/books/Drive - The Surprising Truth About What Motivates Us.jpg',
        audioPath: 'assets/onboarding/sounds/Drive_The_Surprising_Truth_About_What_Motivates_Us_by_Daniel_H._Pink.mp3',
        narrator: 'male',
    },

    yourMoneyOrYourLife: {
        title: 'Your Money or Your Life',
        author: 'Vicki Robin',
        imagePath: 'assets/onboarding/books/Your Money or Your Life.jpg',
        audioPath: 'assets/onboarding/sounds/Your_Money_or_Your_Life_by_Vicki_Robin.mp3',
        narrator: 'male',
    },

    zeroToOne: {
        title: 'Zero to One',
        author: 'Peter Thiel',
        imagePath: 'assets/onboarding/books/Zero to One.jpg',
        audioPath: 'assets/onboarding/sounds/Zero_to_One_by_Peter_Thiel.mp3',
        narrator: 'male',
    },

    the5LoveLanguages: {
        title: 'The 5 Love Languages',
        author: 'Gary Chapman',
        imagePath: 'assets/onboarding/books/The 5 Love Languages.jpg',
        audioPath: 'assets/onboarding/sounds/The_5_Love_Languages_by_Gary_Chapman.mp3',
        narrator: 'female',
    },

    attached: {
        title: 'Attached',
        author: 'Amir Levine and Rachel Heller',
        imagePath: 'assets/onboarding/books/Attached The New Science of Adult Attachment and How It Can Help You Find—and Keep—Love.jpg',
        audioPath: 'assets/onboarding/sounds/Attached_The_New_Science_of_Adult_Attachment.mp3',
        narrator: 'male',
    },

    howToTalkSoKidsWillListen: {
        title: 'How to Talk So Kids Will Listen & Listen So Kids Will Talk',
        author: 'Adele Faber and Elaine Mazlish',
        imagePath: 'assets/onboarding/books/How to Talk So Kids Will Listen & Listen So Kids Will Talk.jpg',
        audioPath: 'assets/onboarding/sounds/How_to_Talk_So_Kids_Will_Listen_&_Listen_So_Kids_Will_Talk.mp3',
        narrator: 'female',
    },

    theWholeBrainChild: {
        title: 'The Whole-Brain Child',
        author: 'Daniel J. Siegel and Tina Payne Bryson',
        imagePath: 'assets/onboarding/books/The Whole-Brain Child.jpg',
        audioPath: 'assets/onboarding/sounds/The_Whole-Brain_Child.mp3',
        narrator: 'male',
    },

    vagabonding: {
        title: 'Vagabonding',
        author: 'Rolf Potts',
        imagePath: 'assets/onboarding/books/Vagabonding  An Uncommon Guide to the Art of Long-Term World Travel.jpg',
        audioPath: 'assets/onboarding/sounds/Vagabonding.mp3',
        narrator: 'female',
    },

    atlasObscura: {
        title: 'Atlas Obscura',
        author: 'Joshua Foer, Dylan Thuras, and Ella Morton',
        imagePath: 'assets/onboarding/books/Atlas Obscura An Explorer’s Guide to the World’s Hidden Wonders.jpg',
        audioPath: 'assets/onboarding/sounds/Atlas_Obscura_An_Explorer’s_Guide_to_the_World’s_Hidden_Wonders.mp3',
        narrator: 'male',
    },

    theBodyKeepsTheScore: {
        title: 'The Body Keeps the Score',
        author: 'Bessel van der Kolk',
        imagePath: 'assets/onboarding/books/The Body Keeps the Score.jpg',
        audioPath: 'assets/onboarding/sounds/The_Body_Keeps_the_Score.mp3',
        narrator: 'female',
    },

    mansSearchForMeaning: {
        title: 'Man’s Search for Meaning',
        author: 'Viktor E. Frankl',
        imagePath: 'assets/onboarding/books/Man’s Search for Meaning.jpg',
        audioPath: 'assets/onboarding/sounds/Man’s_Search_for_Meaning.mp3',
        narrator: 'male',
    },

    atomicHabits: {
        title: 'Atomic Habits',
        author: 'James Clear',
        imagePath: 'assets/onboarding/books/Atomic Habits.jpg',
        audioPath: 'assets/onboarding/sounds/Atomic_Habits.mp3',
        narrator: 'female',
    },

    theObesityCode: {
        title: 'The Obesity Code',
        author: 'Dr. Jason Fung',
        imagePath: 'assets/onboarding/books/The Obesity Code.jpg',
        audioPath: 'assets/onboarding/sounds/The_Obesity_Code.mp3',
        narrator: 'male',
    },

    iWillTeachYouToBeRich: {
        title: 'I Will Teach You to Be Rich',
        author: 'Ramit Sethi',
        imagePath: 'assets/onboarding/books/I Will Teach You to Be Rich.jpg',
        audioPath: 'assets/onboarding/sounds/I_Will_Teach_You_to_Be_Rich.mp3',
        narrator: 'female',
    },

    essentialism: {
        title: 'Essentialism',
        author: 'Greg McKeown',
        imagePath: 'assets/onboarding/books/Essentialism The Disciplined Pursuit of Les.jpg',
        audioPath: 'assets/onboarding/sounds/Essentialism_The_Disciplined_Pursuit_of_Less.mp3',
        narrator: 'female',
    },

    grit: {
        title: 'Grit: The Power of Passion and Perseverance',
        author: 'Angela Duckworth',
        imagePath: 'assets/onboarding/books/Grit The Power of Passion and Perseverance.jpg',
        audioPath: 'assets/onboarding/sounds/Grit_The_Power_of_Passion_and_Perseverance.mp3',
        narrator: 'female',
    },

    daringGreatly: {
        title: 'Daring Greatly',
        author: 'Brené Brown',
        imagePath: 'assets/onboarding/books/Daring Greatly.jpg',
        audioPath: 'assets/onboarding/sounds/Daring_Greatly.mp3',
        narrator: 'male',
    },

    sapiens: {
        title: 'Sapiens: A Brief History of Humankind',
        author: 'Yuval Noah Harari',
        imagePath: 'assets/onboarding/books/Sapiens A Brief History of Humankind.jpg',
        audioPath: 'assets/onboarding/sounds/Sapiens_A_Brief_History_of_Humankind.mp3',
        narrator: 'female',
    },

    quiet: {
        title: 'Quiet',
        author: 'Susan Cain',
        imagePath: 'assets/onboarding/books/The 80 20 Principle The Secret to Achieving More with Less.jpg',
        audioPath: 'assets/onboarding/sounds/Quiet_The_Power_of_Introverts_in_a_World_That_Can’t_Stop_Talking.mp3',
        narrator: 'male',
    },

    the8020Principle: {
        title: 'The 80/20 Principle',
        author: 'Richard Koch',
        imagePath: 'assets/onboarding/books/The 80 20 Principle The Secret to Achieving More with Less.jpg',
        audioPath: 'assets/onboarding/sounds/80_20_Principle_The_Secret_to_Achieving_More_with_Less.mp3',
        narrator: 'female',
    },
} as const;

export const goalsBooksMap: { [key in Goal]: Book[] } = {
    '🏠 Buy a house': [
        books.theMillionaireRealEstateInvestor,
        books.richDadPoorDad,
    ],

    '👩‍💼 Getting a promotion': [
        books.the7HabitsOfHighlyEffectivePeople,
        books.drive,
    ],

    '💸 Pay the Debt': [
        books.theTotalMoneyMakeover,
        books.yourMoneyOrYourLife,
    ],

    '📊 Starting my own business': [
        books.theLeanStartup,
        books.zeroToOne,
    ],

    '👩‍❤️‍👩 Relationship commitment': [
        books.the5LoveLanguages,
        books.attached,
    ],

    '👨‍👩‍👧 Parenthood': [
        books.howToTalkSoKidsWillListen,
        books.theWholeBrainChild,
    ],

    '🌍 Travel the World': [
        books.vagabonding,
        books.atlasObscura,
    ],

    '🏖 Retirement planning': [
        books.theSimplePathToWealth,
        books.randomWalkDownWallStreet,
    ],

    '🤯 Mental and emotional well-being': [
        books.theBodyKeepsTheScore,
        books.mansSearchForMeaning,
    ],

    '💪 Improve health and fitness': [
        books.atomicHabits,
        books.theObesityCode,
    ],

    '🎓 Get the best education': [ //TODO: add tailored books
        books.atomicHabits,
        books.the7HabitsOfHighlyEffectivePeople,
    ],

    '🤔 Other': [
        books.atomicHabits,
        books.the7HabitsOfHighlyEffectivePeople,
    ],
}

export const challengesBookMap: { [key in JobChallenge]: Book } = {
    '💸 Low income': books.iWillTeachYouToBeRich,
    '🪙 Financial dependence': books.thePsychologyOfMoney,
    '⏳ No free time': books.essentialism,
    '🔄 Routine': books.theWarOfArt,
    '😰 Constant stress': books.burnout,
    '🤢 Toxic environment': books.radicalCandor,
    '🌱 No growth': books.grit,
    '🧍 Feeling undervalued': books.daringGreatly,
    '😎 Everything is perfect': books.sapiens,
    '❌ I\'m not currently working': books.quiet,
    '✏️ Other': books.the8020Principle,
}
