import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailLoginComponent } from './components/authentication/email-login/email-login.component';
import { LinkLoginComponent } from './components/authentication/link-login/link-login.component';
import { LoginLinkInfoComponent } from './components/authentication/login-link-info/login-link-info.component';
import { LoginComponent } from './components/authentication/login/login.component';
import { LogoutComponent } from './components/authentication/logout/logout.component';
import { InternalErrorComponent } from './components/common/internal-error/internal-error.component';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { EducationLandingComponent } from './components/landings/education/education-landing.component';
import { ExistingUserDiscountPaywallComponent } from './components/landings/existing-user-discount-paywall/existing-user-discount-paywall.component';
import { RootLandingComponent } from './components/landings/root-landing/root-landing.component';
import { PaymentResultComponent } from './components/payment-result/payment-result.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SuccessComponent } from './components/success/success.component';
import { authGuard } from './guards/auth.guard';
import { StoreButtonComponent } from './w2a/components/store-button/store-button.component';
import { StoreRedirectComponent } from './w2a/components/store-redirect/store-redirect.component';

const routes: Routes = [
    { path: '', component: RootLandingComponent },
    { path: 'education', component: EducationLandingComponent },
    { path: 'user-discount', component: ExistingUserDiscountPaywallComponent },
    { path: 'payment-result', component: PaymentResultComponent },
    { path: 'account', component: ProfileComponent, canActivate: [authGuard] },
    { path: 'error-500', component: InternalErrorComponent },

    { path: 'authentication/login', component: LoginComponent },
    { path: 'authentication/logout', component: LogoutComponent },
    { path: 'authentication/email', component: EmailLoginComponent },
    { path: 'authentication/link', component: LinkLoginComponent },
    { path: 'authentication/link-info', component: LoginLinkInfoComponent },

    { path: 'w2a/go-to-store', component: StoreButtonComponent },
    { path: 'w2a/redirect', component: StoreRedirectComponent },

    { path: 'login', component: LoginComponent }, 
    { path: 'success', component: SuccessComponent },

    //it can be lazy loaded if needed
    { path: 'onboarding', loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule) },

    { path: '**', component: NotFoundComponent } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}