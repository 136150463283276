import { Goal } from './quiz';

export interface GoalIndex {
    nameSynonym: string;
    indexAbbreviation: string;
    indexName: string;
    source: string;
}

export const goalIndexSourceMap: { [key in Goal]: GoalIndex } = {
    '🏠 Buy a house': {
        nameSynonym: 'own a home',
        indexAbbreviation: 'HPI',
        indexName: 'Homeownership Preparedness Index',
        source: 'Harvard Joint Center for Housing Studies',
    },
    '👩‍💼 Getting a promotion': {
        nameSynonym: 'advance in career',
        indexAbbreviation: 'PRI',
        indexName: 'Promotion Readiness Index',
        source: 'Forbes Career Development Report',
    },
    '💸 Pay the Debt': {
        nameSynonym: 'become debt-free',
        indexAbbreviation: 'DPI',
        indexName: 'Debt-Free Progress Index',
        source: 'NerdWallet Financial Stability Study',
    },
    '📊 Starting my own business': {
        nameSynonym: 'become an entrepreneur',
        indexAbbreviation: 'ERI',
        indexName: 'Entrepreneur Readiness Index',
        source: 'Cambridge Entrepreneurship Research Centre',
    },
    '👩‍❤️‍👩 Relationship commitment': {
        nameSynonym: 'strengthen relationships',
        indexAbbreviation: 'CSI',
        indexName: 'Commitment Strength Index',
        source: 'The Gottman Institute Relationship Research',
    },
    '👨‍👩‍👧 Parenthood': {
        nameSynonym: 'become a good parent',
        indexAbbreviation: 'PRI',
        indexName: 'Parenting Readiness Index',
        source: 'Pew Research Center Parenting Studies',
    },
    '🌍 Travel the World': {
        nameSynonym: 'see the world',
        indexAbbreviation: 'TPI',
        indexName: 'Travel Preparedness Index',
        source: 'Lonely Planet Global Travel Trends Report',
    },
    '🏖 Retirement planning': {
        nameSynonym: 'secure your retirement',
        indexAbbreviation: 'RRI',
        indexName: 'Retirement Readiness Index',
        source: 'Vanguard Retirement Planning Survey',
    },
    '🤯 Mental and emotional well-being': {
        nameSynonym: 'improve mental health',
        indexAbbreviation: 'EWI',
        indexName: 'Emotional Wellness Index',
        source: 'American Psychological Association (APA)',
    },
    '💪 Improve health and fitness': {
        nameSynonym: 'enhance physical health',
        indexAbbreviation: 'HFPS',
        indexName: 'Health & Fitness Progress Score',
        source: 'Mayo Clinic Wellness Research',
    },
    '🎓 Get the best education': {
        nameSynonym: 'get the best education',
        indexAbbreviation: 'LPI',
        indexName: 'Learning Potential Index',
        source: 'Stanford Center for Learning Sciences',
    },
    '🤔 Other': {
        nameSynonym: 'achieve personal growth',
        indexAbbreviation: 'PGP',
        indexName: 'Personal Growth Potential',
        source: 'MIT Behavioral Sciences Lab',
    },
};