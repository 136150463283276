import { track } from '@amplitude/analytics-browser';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Rive } from '@rive-app/canvas';
import { RiveAnimationConfig } from '../../data/quiz';
import { OnboardingFlowService, QuizTopic } from '../../services/onboarding-flow.service';

@Component({
  selector: 'app-onboarding-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit, AfterViewInit, OnDestroy {
  title: string; 
  subtitle: string;
  mainImagePath: string;

  rive: RiveAnimationConfig = {
    // fileName: 'rocket',
    // animations: ['char', 'rocket'],
    // ratio: 1024 / 580,
    fileName: 'impressed',
    animations: ['char', 'skirt', 'birds', 'wings'],
    ratio: 1.75 / 1,
  };

  @ViewChild('animation') riveAnimation: ElementRef;
  
  private riveInstance: Rive | undefined;

  constructor(
    private flowService: OnboardingFlowService,
  ) {}

  ngOnInit(): void {
    const topic = this.flowService.getTopic();
    
    this.title = this.getTitle(topic);
    this.subtitle = this.getSubtitle(topic);
    this.mainImagePath = this.getMainImagePath(topic);

    track('web_intro_screen_view', {
      topic
    });
  }

  ngAfterViewInit(): void {
    const canvas = this.riveAnimation.nativeElement;

    this.riveInstance = new Rive({
      src: `assets/rive/${this.rive.fileName}.riv`,
      canvas,
      autoplay: true,
      animations: this.rive.animations,
      onLoad: () => this.riveInstance?.resizeToCanvas(), //resizeDrawingSurfaceToCanvas(),
    });
  }

  ngOnDestroy(): void {
    this.riveInstance?.stop();
    this.riveInstance?.cleanup();
  }

  continueButtonClicked(): void {
    this.flowService.handleFinishedIntroScreen();
  }

  private getTitle(topic: QuizTopic | null): string {
    //Maximize Your Time and Boost Productivity with Cutting-Edge AI Tool
    switch (topic) {
      case QuizTopic.femaleInteresting:
        return 'Become the Most Interesting Woman in the Room';
      case QuizTopic.femaleGirl:
        return 'Become a Woman you needed as a Girl';
      case QuizTopic.male:
        return 'A Sharp Mind Makes the Man';
      case QuizTopic.female:
        return 'Become the Most Interesting Woman in the Room';
      case QuizTopic.general:
        return 'Level Up Without Lifting a Finger';
      case QuizTopic.religion:
        return 'Nourish Your Soul Without Missing a Word';
      default:
        return 'Level Up Without Lifting a Finger';
    }
  }

  private getSubtitle(topic: QuizTopic | null): string {
    //Discover how successful professionals stay ahead. Take this quick 2-3 minute quiz!
    switch (topic) {
      case QuizTopic.femaleInteresting:
        return 'Turn any book or article into your personal audio mentor, fueling your growth, confidence, and allure—wherever life takes you.';
      case QuizTopic.general:
        return 'Turn your reading into natural-sounding audio - learn how in just a few steps and unlock your personalized lazy learning plan!';
      case QuizTopic.male:
        return `Stay Ahead with Modern AI - Take the quiz to learn how to stay sharp and confident`;
      case QuizTopic.religion:
        return `Turn your spiritual or motivational reading into uplifting audio, fueling both your faith and personal growth - wherever life takes you.`;
      default:
        return 'Turn any book or article into your personal audio mentor, fueling your growth, confidence, and allure - wherever life takes you.';
    }
  }

  private getMainImagePath(topic: QuizTopic | null): string {
    //Discover how successful professionals stay ahead. Take this quick 2-3 minute quiz!
    switch (topic) {
      case QuizTopic.femaleInteresting:
        return `../../../../assets/onboarding/pictures/woman1.png`;
      case QuizTopic.male:
        return `../../../../assets/onboarding/pictures/mark.jpg`;
      default:
        return `../../../../assets/onboarding/pictures/woman.jpg`;
    }
  }
}
