import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError, EMPTY, filter, map, switchMap, take, tap } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { CustomizerSettingsService } from 'src/app/services/customizer-settings.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ModalComponent, ModalData } from '../../shared/modal/modal.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
  name: string;
  email: string;
  photoURL: string | null;

  isSubscribed = false;
  subscriptionName: string = '';
  subscriptionDate: Date | null;

  constructor(
    public themeService: CustomizerSettingsService,
    private api: ApiService,
    private auth: AuthService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.auth.user$
      .pipe(
        filter(user => !!user),
        take(1),
        switchMap(user => this.api.getUserDetails().pipe(map(details => ({ ...details, ...user })))),
        tap(user => {
          this.name = user.displayName || 'Guest';
          this.email = user.email || 'No Email Address';
          this.photoURL = user.photoURL || '';
          this.isSubscribed = user.subscriptionState.isSubscribed;
          this.subscriptionName = user.webProduct?.billingPeriod || '';
          this.subscriptionDate = user.subscriptionState.expireDate;
        }),
        catchError((error) => {
          console.log(error);

          return EMPTY;
        }),
      )
      .subscribe();
  }

  cancelSubscription() {
    this.dialog.open<ModalComponent, ModalData>(ModalComponent, {
      data: {
        title: 'Warning',
        isConfirmation: true,
        message: 'Are you sure you want to cancel your subscription? The service will not be available.'
      }
    }).afterClosed()
      .pipe(
        filter(isOk => isOk),
        tap(() => this.loadingService.start()),
        switchMap(() => this.api.cancelStripeSubscription()),
        tap(() => this.router.navigate([''])),
        tap(() => this.loadingService.finish()),
        catchError(() => {
          this.loadingService.finish();

          return this.dialog.open<ModalComponent, ModalData>(ModalComponent, {
            data: {
              title: 'Sorry',
              message: 'Something went wrong, please contact support@getpeech.com - we will help.'
            }
          }).afterClosed()
        })
      )
      .subscribe();
  }
}
