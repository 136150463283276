import { Component } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { OnboardingFlowService } from '../../services/onboarding-flow.service';

@Component({
  selector: 'app-goal-input',
  templateUrl: './goal-input.component.html',
  styleUrl: './goal-input.component.scss'
})
export class GoalInputComponent {
  @ViewChild('goalInput') goalInput!: ElementRef;

  goal = new FormControl('', [Validators.required]); //TODO: don't forget to trim it
  
  constructor(private flowService: OnboardingFlowService) {}

  enterPressed(): void {
    if (!this.goal.value || this.goal.invalid) {
      return;
    }

    this.goalInput.nativeElement.blur();

    this.proceed();
  }

  continueButtonClicked(): void {
    if (this.goal.invalid) {
      return;
    }

    this.proceed();
  }

  proceed(): void {
    this.flowService.handleFinishedManualGoal(this.goal.value!)
  }
}
