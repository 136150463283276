import * as amplitude from '@amplitude/analytics-browser';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EMPTY, catchError, distinctUntilChanged, finalize, switchMap, take, tap } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { OnboardingFlowService } from '../../services/onboarding-flow.service';

//TODO: here we should add login through social networks 

@Component({
  selector: 'app-onboarding-sign-in-with-email',
  templateUrl: './sign-in-with-email.component.html',
  styleUrls: ['./sign-in-with-email.component.scss']
})
export class SignInWithEmailComponent {
  signIn = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    password: new FormControl('', [Validators.required]),
  });
  
  hidePassword = true;
  signInError = ''

  ngOnInit(): void {
    this.signIn.valueChanges.pipe(
      distinctUntilChanged(),
      tap(() => this.signInError = ''),
    )
    .subscribe();
  }

  constructor(
    private authService: AuthService,
    private api: ApiService,
    private flowService: OnboardingFlowService,
    private loadingService: LoadingService,
  ) {}

  continueButtonClicked(): void {
    const { email, password } = this.signIn.value;

    if (!email || !password || this.signIn.get('email')?.invalid) {
      return;
    }

    // this.loadingService.start();

    // this.authService.loginWithEmail(email, password)
    //   .pipe(
    //     take(1),
    //     tap(user => {
    //       if (!user) {
    //         throw 'Something went wrong';
    //       }
    //     }),
    //     switchMap(() => this.api.createStripeCustomer(email, {
    //       name: this.flowService.getName(),
    //       amplitudeDeviceId: amplitude.getDeviceId(),
    //     })),
    //     take(1),
    //     tap(({ customerId }) => this.flowService.handleFinishedLogin(customerId)),
    //     catchError(error => {
    //       if(error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
    //         this.signInError = 'Invalid email or password'
    //       } else {
    //         this.signInError = 'Something went wrong. Please try again later.'
    //       }
    //       return EMPTY;
    //     }),
    //     finalize(() => this.loadingService.finish()),
    //   )
    // .subscribe();
  }
}
