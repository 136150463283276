import * as amplitude from '@amplitude/analytics-browser';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, takeWhile, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StatsIGService } from './services/stats-ig.service';
import { OnboardingFlowService, QuizTopic } from './services/onboarding-flow.service';

@Component({
  selector: 'app-onboarding-root',
  templateUrl: './onboarding-root.component.html',
  styleUrl: './onboarding-root.component.scss'
})
export class OnboardingRootComponent implements OnInit, OnDestroy {
  isLogoShown: boolean = true;
  isActive: boolean = true;

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private flowService: OnboardingFlowService,
    private abService: StatsIGService,
  ) {}

  ngOnInit(): void {
    this.isLogoShown = !this.router.url.includes('checkout');

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap(() => {
          this.isLogoShown = !this.router.url.includes('checkout')
      }),
      takeWhile(() => this.isActive),
    ).subscribe();

    this.startAnalytics();

    const topic = this.route.snapshot.queryParamMap.get('topic') as QuizTopic;

    this.flowService.setTopic(topic);
  }

  startAnalytics() {
    amplitude.init(environment.amplitudeKey, {
      defaultTracking: true,
      appVersion: environment.appVersion,
      autocapture: {
        pageViews: false,
      }
    });

    const placementNumber = this.abService
      .getExperiment('pricing_test_1')
      .get('placementNumber', 1) as 1 | 2;

    this.abService.setConfigProperty({
      placementNumber,
    });

    amplitude.track({
      event_type: 'web_onboarding_started',
      user_properties: {
        web_onboarding_version: environment.appVersion,
        web_ab_price_placement_number: placementNumber,
      }
    });

    this.abService.logEvent('test_init');
  }

  ngOnDestroy(): void {
    this.isActive = false;
  }
}
