import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@firebase/auth';
import { catchError, take, tap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ModalComponent, ModalData } from 'src/app/shared/modal/modal.component';
import { LoginTarget } from '../login/login.component';

//http://localhost:4200/authentication/link?target=account&token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTczNzY0NzM1OSwiZXhwIjoxNzM3NjUwOTU5LCJpc3MiOiJwZWVjaC10ZXh0LXRvLXNwZWVjaEB2b2ljZXNlcnZpY2UtMjE3MDIxLmlhbS5nc2VydmljZWFjY291bnQuY29tIiwic3ViIjoicGVlY2gtdGV4dC10by1zcGVlY2hAdm9pY2VzZXJ2aWNlLTIxNzAyMS5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInVpZCI6Im1VUGl2bmx2aUloOGwzaGpqc0dlVDNZMXZqRjIifQ.Nw4C1rCZWxbbGBXFnVcp1r2wWS_-S4_o08JUHt5_ZLXmZF_QOwPXWdms5Dpm6jABwE2bwxFqJ8RK3iqQs7v3MCwo2LEHESHIPu2XkmpMWtcnhIIhawZg-L0SCkcBQ3SI0Yke90YkW61YP_4SRhhhO3VJ9_cahHlh-MdZDtEK9YvURgl3BJu2IcZYP3tUlBUzF0RMOA5gGfmOIDkfOjrn02hsmTr5-J6ROSce_e1XepQSUQlvXz96GEWi2BuPDLHPB_ZdfBrNlO1Z62iWXmrTbnEGQW_EkJXYoUMGYpJhoEy63o-JXIG33FpyRDYHEA8JInutVeLREposYNgV6hUHmA

@Component({
  selector: 'app-link-login',
  templateUrl: './link-login.component.html',
  styleUrl: './link-login.component.scss'
})
export class LinkLoginComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService, private dialog: MatDialog) { }

  ngOnInit(): void {
    const token = this.route.snapshot.queryParams['token'];
    const target = this.route.snapshot.queryParams['target'] as LoginTarget;

    if(!token){
      console.log('No token found');
      this.router.navigate(['authentication', 'login']);
      return;
    }

    this.authService.loginWithToken(token)
        .pipe(
          take(1),
          tap((user) => {
            if (user) {
              this.handleLogin(target, user);
            } else {
              throw new Error('Failed to Sign You In. Please try again.');
            }
          }),
          catchError((error) => { 
            console.log(error);
  
            return this.dialog.open<ModalComponent, ModalData>(ModalComponent, {
              data: {
                title: 'Sorry',
                message: 'Failed to Sign You In. Please try again.'
              }
            })
              .afterClosed()
              .pipe(
                tap(() => this.router.navigate(['authentication', 'login']))
              );
          }))
        .subscribe();
  }

  private handleLogin(target: LoginTarget, user: User) {
    if (target === 'chrome-extension') {
      this.router.navigate(['/success'], {
        queryParams: {
          destination: 'peech-chrome-extension', //TODO: change it in chrome extension's code base
          token: user.refreshToken,
        }
      });
      return;
    }

    if (target === 'account') {
      this.router.navigate(['/account']);
      return;
    }

    console.log('Unknown Target');

    this.router.navigate(['/account']);
  }
}
