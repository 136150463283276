import { AudioTrack } from "src/app/shared/audio-track/audio-track.component";
import { Interest } from "./quiz";

// {[key: string]: AudioTrack} 
export const audioTracks = {
  news: {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/news.mp3',
    title: 'Reuters',
  },

  history: {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/history.mp3',
    title: 'New History: The Cold War',
  },

  business: {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/business_management.mp3',
    title: 'Harvard Business Review',
  },

  medicine: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/clinical_med.mp3',
    title: 'The Lancet',
  },

  physics: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/physics.mp3',
    title: 'Nature Physics',
  },

  darkRomance: {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/dark_romance.mp3',
    title: 'Wuthering Heights',
  },

  environment: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/environment.mp3',
    title: 'National Geographic',
  },

  fanfiction: {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/fanfiction.mp3',
    title: 'A03, by Harry Potter',
  },

  fantasy: {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/fantasy.mp3',
    title: 'The Lord of the Rings',
  },

  finance: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/finance.mp3',
    title: 'The Wall Street Journal',
  },

  fitness: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/fitness.mp3',
    title: `Men’s Health`,
  },

  marketing: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/marketing.mp3',
    title: 'Adweek',
  },

  mentalHealth: {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/mental_health.mp3',
    title: 'Psychology Today',
  },

  parenting: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/parenting.mp3',
    title: 'Parents Magazine',
  },

  personalGrowth: {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/personal_growth_motivation.mp3',
    title: 'Tony Robbins',
  },

  religion: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/religious.mp3',
    title: 'John 3:16-18, Bible',
  },

  romance: {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/romance.mp3',
    title: 'Bridget Jones’s Diary',
  },

  science: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/science.mp3',
    title: 'Scientific American',
  },

  thrillerMystery: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/thriller_mystery.mp3',
    title: 'The Da Vinci Code',
  },

  wealthBuilding: {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/wealth_building.mp3',
    title: 'Forbes',
  },

  wellBeing: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/well_being.mp3',
    title: 'Mindful Magazine',
  },

  math: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/mathematics.mp3',
    title: 'Euclid’s Elements',
  },

  literature: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/literature.mp3',
    title: 'Shakespeare Explained',
  },

  cs: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/computer_science.mp3',
    title: 'Introduction to Algorithms',
  },

  law: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/law.mp3',
    title: 'Civil Code',
  },

  economics: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/economics.mp3',
    title: 'Principles of Economics',
  },

  politics: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/political_science.mp3',
    title: 'Democracy in America',
  },

  biology: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/biology.mp3',
    title: 'Photosynthesis',
  },

  arts: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/arts.mp3',
    title: 'The Story of Art',
  },
};

export const languageTracks = {
  en: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/langs/2lang_en.mp3',
    title: 'English',
  },
  es: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/langs/2lang_es.mp3',
    title: 'Spanish',
  },
  fr: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/langs/2lang_fr.mp3',
    title: 'French',
  },
  de: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/langs/2lang_de.mp3',
    title: 'German',
  },
  it: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/langs/2lang_it.mp3',
    title: 'Italian',
  },
  pt: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/langs/2lang_pt.mp3',
    title: 'Portuguese',
  },
  zh: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/langs/2lang_zh.mp3',
    title: 'Chinese',
  },
  hi: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/langs/2lang_hi.mp3',
    title: 'Hindi',
  },
};

export const interestTrackMap: { [key in Interest]: AudioTrack } = {
    "🧠 Personal Growth": audioTracks.personalGrowth,
    "💼 Business": audioTracks.business,
    "💻 Tech & IT": audioTracks.cs,
    "📊 Finance & Investing": audioTracks.finance,
    "📣 Marketing": audioTracks.marketing,
    "🏥 Health & Medicine": audioTracks.medicine,
    "🌍 Environment": audioTracks.environment,
    "📖 Books": audioTracks.literature,
    "🎥 Movies": audioTracks.fanfiction,
    "🛡 Fantasy": audioTracks.fantasy,
    "🔍 Thriller & Mystery": audioTracks.thrillerMystery,
    "❤️ Romance": audioTracks.romance,
    "👪 Family": audioTracks.parenting,
    "🚀 Motivation": audioTracks.personalGrowth,
    "📜 Religion & Spirituality": audioTracks.religion,
    "⚖️ Law": audioTracks.law,
    "🧮 Economics": audioTracks.economics,
    "✈️ Travel": audioTracks.history, // No matching audio track
    "🎨 Arts & Design": audioTracks.arts,
    "🏋️ Fitness": audioTracks.fitness,
    "💆 Mental Wellness": audioTracks.mentalHealth,
    "🍳 Food & Cooking": audioTracks.environment, // No matching audio track
    "📜 History": audioTracks.history,
    "🎙️ Content Creation": audioTracks.arts, // No matching audio track
    "🏡 Real Estate": audioTracks.business, // No matching audio track
    "📰 News": audioTracks.news
};
