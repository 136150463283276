<div class="row android-container align-items-center flex-column pb-20">
    <div class="col-lg-6 col-md-12 mt-20 top-section d-flex align-items-center flex-column">
        <div class="text-center">
            <h3 [innerHTML]="title"></h3>
            <div class="subtitle mt-20" [innerHTML]="subtitle"></div>        
        </div>

        <div class="tagus-form-group mt-30">
            <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
            <mat-form-field appearance="fill">
                <i class="ri-mail-line"></i>
                <mat-label>Enter email address</mat-label>
                <input #emailInput matInput autocomplete="email" placeholder="harry@hogwarts.com" type="email" [formControl]="email" (keyup.enter)="enterPressed()" required>
                <mat-error *ngIf="email.invalid">Email address is invalid</mat-error> 
            </mat-form-field>
            <div class="muted-color fs-12">We respect your privacy and are committed to protect your personal data. Email will be used only to send you notification once the app becomes available.</div>
        </div>

        <button mat-flat-button class="tagus mt-20" [disabled]="email.invalid" (click)="continueButtonClicked()">Continue</button>
    </div>
    <app-social-proof class="mt-20"></app-social-proof>
</div>
