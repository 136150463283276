<div class="quiz-container">
  <div class="progress-bar">
    <div class="progress-thumb" [style.width.%]="100 * (currentIndex + 1) / stepsCount"></div>
  </div>
  <div class="quiz-step">
    <div class="icon" *ngIf="data.topIcon">{{ data.topIcon }}</div>
    <div class="title" [innerHTML]="data.title"></div>
    <div class="subtitle" *ngIf="data.subTitle && !(data.isVoting || data.rive || data.videoFilePath || data.imageFilePath || data.headline)">
      {{ data.subTitle }}
    </div>
    <div *ngIf="data.options || data.message || data.mediaCite || data.headline || data.rive || data.videoFilePath || data.imageFilePath || data.audioTracks || data.hasSocialProof || data.userReview" class="content-container d-flex flex-column mt-20">
      
      <form [formGroup]="form">
        <ng-container *ngIf="data.options && !data.isChips">
            <!-- Multiple selection list -->
          <mat-selection-list
            *ngIf="data.isMultiple"
            [ngClass]="{
              'grid-list-odd': data.options.length % 2,
              'grid-list': data.isTilesDisplay
            }"
            [multiple]="true"
            formControlName="selectedOptions"
            [disableRipple]="true"
            (selectionChange)="optionClicked()"
          >
            <mat-list-option *ngFor="let option of data.options" [value]="option.title" [ngClass]="{ image: option.imageFilePath }">
              <app-quiz-list-option [option]="option"></app-quiz-list-option>
            </mat-list-option>

          </mat-selection-list>

          <!-- Single selection list -->
          <mat-selection-list
            *ngIf="!data.isMultiple"
            [ngClass]="{
              'grid-list-odd': data.options.length % 2,
              'grid-list': data.isTilesDisplay
            }"
            [multiple]="false"
            formControlName="selectedOptions"
            [disableRipple]="true"
            (selectionChange)="optionClicked()"
          >
            <mat-list-option *ngFor="let option of data.options" [value]="option.title" [ngClass]="{ image: option.imageFilePath }">
              <app-quiz-list-option [option]="option"></app-quiz-list-option>
            </mat-list-option>

          </mat-selection-list>

        </ng-container>

        <ng-container *ngIf="data.options && data.isChips">
          <mat-chip-listbox
            formControlName="selectedOptions"
            [multiple]="true"
            aria-label="Options selection"
          >
            <mat-chip-option *ngFor="let option of data.options" [value]="option.title">
              {{ option.title }}
            </mat-chip-option>
          </mat-chip-listbox>
        </ng-container>
      </form>

      <canvas
        #animation
        class="animation"
        width="1024"
        [height]="1024 / (data.rive?.ratio || 1)"
        [ngClass]="{ 'd-none': !data.rive }"
        [ngStyle]="{ 'background-image': getBackgroundImageUrl(), 'margin-top': data.rive?.marginTop }"
      ></canvas>

      <video
        *ngIf="data.videoFilePath"
        class="video"
        [src]="data.videoFilePath + '#t=0.1'"
        type="video/mp4"
        autoplay
        muted
        playsinline
      ></video>

      <div *ngIf="data.mediaCite" class="cite light-green-color border-radius p-25 d-flex flex-column justify-content-center mt-15">
        <div class="d-flex content flex-grow-1">
            <div class="d-flex">
                <div class="quotas-image image mt-10"></div>
            </div>
            <div class="flex-grow-1 d-flex flex-column justify-content-space-between">
                <div class="review">Peech is a text-to-speech app that actually sounds good - an essential tool for successful individuals.</div>
            </div>
        </div>
        <div class="mt-20 d-flex justify-content-center">
            <div class="media-logo image"></div>
        </div>
      </div>

      <div class="message light-green-color border-radius" [class.d-none]="!data.message">
        <span #typingText></span><span class="cursor" #cursor>|</span>
      </div>

      <div *ngIf="data.imageFilePath" class="image flex-grow-1" [ngStyle]="{ 'background-image': getBackgroundImageUrl() }"></div>

      <h1 *ngIf="data.headline" class="display-1 headline" [class.negative]="data.isNegative">{{ data.headline }}</h1>

      <div *ngIf="data.subTitle && (data.isVoting || data.rive || data.videoFilePath || data.imageFilePath || data.headline)" class="subtitle bottom">
        {{ data.subTitle }}
      </div>

      <ng-container *ngIf="data.audioTracks">
        <app-audio-track
          *ngFor="let track of data.audioTracks"
          #audioTrackRef
          [data]="track"
          (onPlayed)="onPlay($event, track)"
        ></app-audio-track>
      </ng-container>

      <app-social-proof *ngIf="data.hasSocialProof"></app-social-proof>

      <app-user-review
        *ngIf="data.userReview"
        [data]="data.userReview"
      ></app-user-review>
    </div>
  </div>

  <div *ngIf="(!data.options || data.isChips || data.isMultiple) && !data.isVoting" class="quiz-footer gradient d-flex justify-content-center">
    <button mat-flat-button 
      class="tagus" 
      [disabled]="data.isRequired && (data.options && (!form.value.selectedOptions || form.value.selectedOptions.length === 0)) || (data.message && !isTypingMessagePlayed)" (click)="buttonClicked()">
      Continue
    </button>
  </div>

  <div *ngIf="data.isVoting" class="quiz-footer d-flex justify-content-center align-items-center">
    <button class="voting-button" (click)="voteClicked('no')">👎</button>
    <button class="voting-button" (click)="voteClicked('miss')">🤷‍♂️</button>
    <button class="voting-button" (click)="voteClicked('yes')">👍</button>
  </div>
</div>
